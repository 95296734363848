.home.site-main,
.static-page {
  .hero .container-lg {
    padding-top: 50px;
  }

  // home page overrides css
  .donate-warning-navbar__main {
    background-image: url(#{$path-to-img}/navbar/donate_warning/Transparent.png);
    border: 1px solid var(--Text-Disabled, #D1D3D4);

    position: absolute;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1344px;
    margin: 0;
    z-index: 1000;

    .donate-warning-navbar__text {
      color: var(--Text-Primary, #323232);
    }

    .donate-warning-navbar__button:first-child:after {
      background-color: var(--Text-Input, #A6A6A6);
    }
  }
}

.donate-warning-navbar {
  display: none;
  margin: 0 48px;
  border-radius: 16px;
  z-index: 10;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  background-position: center;

  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
}

.donate-warning-navbar__watch {
  background-image: url(#{$path-to-img}/navbar/donate_warning/Dark.png);
  border: 1px solid var(--Grayscale-Grey, #444050);

  .donate-warning-navbar__text {
    color: var(--Backgrounds-White, #FFF);
  }

  .donate-warning-navbar__button:first-child:after {
    background-color: var(--Text-Secondary, #5C5C5C);
  }
  .donate-warning-navbar__close path {
    fill: #8F8A9B;
  }
}

:not(.site-watch) > .donate-warning-navbar__main,
.donate-warning-navbar__custom {
  background-image: url(#{$path-to-img}/navbar/donate_warning/Light.png);
  border: 1px solid var(--Text-Disabled, #D1D3D4);

  .donate-warning-navbar__text {
    color: var(--Text-Primary, #323232);
  }

  .donate-warning-navbar__button:first-child:after {
    background-color: var(--Text-Input, #A6A6A6);
  }
  .donate-warning-navbar__close path {
    fill: #5C5C5C;
  }
}


.site-watch .donate-warning-navbar {
  @extend .donate-warning-navbar__watch;
}

.donate-warning-navbar__close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.donate-warning-navbar__text {
  max-width: 921px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  margin: 0 auto;
}

.donate-warning-navbar__buttons {
  text-align: center;
  margin-top: 4px;
}

.donate-warning-navbar__button {
  color: var(--Primary-Steel-Blue-Pressed, #4C7C93);
  position: relative;
  font-size: 16px;
  font-weight: 500;
  padding: 0 8px;

  &:first-child:after {
    content: '';
    height: 13px;
    width: 1px;
    position: absolute;
    right: -3px;
    top: 25%;
  }
}


// COLORS
@media (max-width: 1000px) {
  .home.site-main {
    .hero .container-lg {
      padding-top: 0;
    }
  }

  .donate-warning-navbar {
    position: fixed !important;
    top: auto !important;
    bottom: 16px !important;

    padding-left: 16px;
    padding-right: 16px;
    margin-left: 0 !important;
    margin-right: 0 !important;

    width: 95% !important;

    background-image: none !important;
    height: auto !important;

    left: 50%;
    transform: translateX(-50%);

    &.donate-warning-navbar__watch {
      border: 1px solid var(--Text-Secondary-light, #8B8B8B);
      background: rgba(115, 115, 115, 0.70);
      backdrop-filter: blur(4px);

      .donate-warning-navbar__text {
        color: var(--Backgrounds-White, #FFF);
      }

      .donate-warning-navbar__button {
        color: var(--Backgrounds-White, #FFF);
        background: var(--Grayscale-Grey, #444050);
        box-shadow: 0 1px 10px 0 rgba(29, 32, 35, 0.15);
      }
    }

    &.donate-warning-navbar__main,
    &.donate-warning-navbar__custom {
      border: 1px solid var(--Text-Input, #A6A6A6);
      background: rgba(148, 148, 148, 0.70);
      backdrop-filter: blur(4px);

      .donate-warning-navbar__text {
        color: var(--Backgrounds-White, #FFF);
      }

      .donate-warning-navbar__button {
        color: var(--Primary-Steel-Blue-Default, #6998AE);
        background: var(--Backgrounds-White, #FFF);
        box-shadow: 0 1px 10px 0 rgba(29, 32, 35, 0.15);
      }
    }

    .donate-warning-navbar__text {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 8px;
    }

    .donate-warning-navbar__button {
      padding: 9px 16px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
    }

    .donate-warning-navbar__button:first-child:after {
      background-color: transparent;
    }

    .donate-warning-navbar__close {
      top: 8px;
      right: 8px;
      border-radius: 50%;
      background-color: var(--System-Background-Light-Secondary, #F2F2F7);
      backdrop-filter: blur(21.746253967285156px);
      padding: 6px;
    }
  }
}