@import 'alchemy/elements/watch/shared/watch_section_title';

.watch-section {
  padding-left: 1.375rem; // 22px

  @include media-breakpoint-up(md) {
    padding-left: 2.4375rem; // 39px
  }

  // TODO: move to shared/_entry.scss
  .watch-entry {
    @extend .d-inline-block;

    width: $entry-card-thumbnail-width;
    vertical-align: top;
    margin-right: 0.625rem; // 10px
    margin-bottom: 2rem; // 32px;
  }

  .view-all-link {
    font-size: 1.25rem;
  }

  // ">" arrow icon, for both desktop and mobile
  .view-all-icon {
    @extend .position-relative;

    left: -0.85rem;
    top: -1px;
  }

  .section-title {
    @extend .pr-0;
    @extend %watch-section-title-font;

    // title as a link, for mobile
    .title-link {
      @extend .text-white;
      @extend .text-nowrap;

      @include hover-focus-active {
        color: $manatee !important;
      }

      @include media-breakpoint-up(sm) {
        @include hover-focus-active {
          color: $white !important;
        }
      }
    }

    .view-all-icon {
      @extend .d-sm-none;
    }
  }

  // "View All >" link next to the section title, for desktop
  .section-heading .view-all-link {
    @extend .d-none;
    @extend .d-sm-inline;

    color: $manatee;

    @include hover-focus-active {
      color: $white;
    }
  }

  .view-all-slide {
    @extend .position-relative;
    @extend .d-inline-block; // like .watch-entry instances

    width: $entry-card-thumbnail-width;
    height: $entry-card-thumbnail-height;
    background-color: $secondary-bg;
  }

  // "View All" card surrounding link
  .view-all-slide .view-all-link {
    @extend .text-white;

    @include hover-focus-active {
      color: $manatee !important;
    }
  }

  .view-all-slide .entry-content {
    @extend .position-absolute;
    @extend .w-100;
    @extend .h-100;
  }

  .view-all-slide .view-all-text {
    @include centered-absolute;
  }

  .slider-spacious {
    .flickity-button {
      @extend .d-none;
    }
  }

  [data-flickity]:not(.flickity-enabled) {
    display: initial;
  }

  .flickity-button {
    @extend .d-none;
    @extend .d-md-block;

    top: 0;
    // Remove translateY(-50%) from the button
    transform: none;
    height: 140px;

    $bg-color: #0b090f;
    background-color: rgba($bg-color, 0.6);

    &:not(:disabled):not(.disabled) {
      @include hover-focus-active {
        background-color: rgba($bg-color, 0.8);
      }
    }

    &:disabled {
      @extend .d-none;
    }

    &:before {
      font-size: 1.75rem;
    }

    // Change next button icon.
    // See app/frontend/stylesheets/fonts/feather.scss for reference.
    &.next:before {
      content: '\e930';
    }

    // Change previous button icon.
    // See app/frontend/stylesheets/fonts/feather.scss for reference.
    &.previous:before {
      content: '\e92f';
    }
  }
}

.watch-section:not(:has(.topic-link))
{
  &:not(:first-child) {
    margin-top: 40px;
  }
}
