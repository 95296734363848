.watch-grid-container {
  padding-top: 1.5rem; // 24px

  @include media-breakpoint-up(xl) {
    padding-top: 3.625rem;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.watch-grid {
  @extend .justify-content-center;
}

.watch-grid-spacer {
  width: 50%; // max-width for .col-6 applied to grid item

  @include media-breakpoint-up(md) {
    width: 33.33333%; // max-width for .col-md-4 grid item
  }

  @include media-breakpoint-up(xl) {
    // .col-xl-auto shrinks column to the thumbnail width
    width: $entry-card-thumbnail-width + $grid-gutter-width;
  }
}

.watch-grid .watch-entry {
  margin-bottom: 2.25rem; // 36px

  @include media-breakpoint-up(xl) {
    width: $entry-card-thumbnail-width;
  }

  .thumbnail {
    height: 90px;

    @include media-breakpoint-up(sm) {
      height: $entry-card-thumbnail-height;
    }

    @include media-breakpoint-up(md) {
      height: $entry-card-thumbnail-height * 1.25;
    }
  }

  .topics {
    @extend .d-none;
    @extend .d-md-block;
  }
}
