.body.site-watch {
  // for "footer at bottom"
  @extend .d-flex;
  @extend .flex-column;
  min-height: 100vh;
  height: 100vh;

  .main {
    flex: 1 0 auto;
  }
}
