.tagline {
  @extend .font-weight-bold;
  @extend .text-uppercase;

  font-size: $font-size-xs;
  line-height: calc(1.125rem + 1px);
  letter-spacing: 0.18em;
  font-style: normal;
}

.trending-section-tagline {
  @extend .font-weight-normal;

  font-size: $font-size-base;
}

// TinyMCE underline span
.underlined {
  text-decoration: none !important;
  background-image: linear-gradient(
    to right,
    $underlines-secondary 0%,
    $underlines-secondary 100%
  );
  background-position: 0 0.95em;
  background-size: auto 8px;
  background-repeat: repeat-x;
}

// increase font weight of the Bold selected text in the RichText editor
strong {
  font-weight: $font-weight-bolder;
}
