// It was decided to completely separate styles for both sites' navbars to allow
// different markup for every of them with minimal interference and consequences.
// But, let's gradually move common styles here, ensuring that don't interfere between
// Alchemy Sites' navbars.
.navbar {
  .navbar-container {
    @extend .w-100;
    @extend .py-1;
  }

  .nav-shadow {
    box-shadow: 0 0.313rem 1rem rgb(157 156 170 / 30%);
  }

  .logo {
    width: 145px;
    height: 32px;
    transition: fill 0.3s ease-in;
  }
}

#search-app,
#search-bar-app,
#search-bar-app-mobile {
  display: block;
}

.main-nav-shadow {
  box-shadow: 0 0.313rem 1rem rgb(157 156 170 / 30%);
}
