.watch-all-speakers-section {
  .flickity-resize {
    .speaker-entry {
      min-height: 100%;
    }
  }

  .speaker-entry {
    @extend .text-center;

    margin-right: 1.75rem;
    width: 15rem;
  }

  .thumbnail-wrapper {
    @extend .mx-auto;
    @extend .mb-3;

    float: none;
  }

  .topics {
    @extend .mt-3;
    @extend .text-left;
  }

  .view-all-link {
    @extend .d-block;
    @extend .text-white;
    @extend .font-weight-bold;

    padding-top: 50%;
    padding-bottom: 50%;
  }
}
