// Admin
#manage-banner-app,
#donate-banner-preview-app {
  display: block;
}
#manage-banner-app {
  width: 500px;
}
#donate-banner-preview-app {
  width: 834px;
  &.satellite-banner {
    width: 834px;
  }
  &.article-banner {
    width: 732px;
    .donate__button-us,
    .donate__button-ca,
    .donate__button-explore-projects {
      width: 188px;
    }
  }
}
#banner-preview-area {
  padding: 10px;
  width: 100%;
  border-radius: 5px;

  #donate-warning-navbar__close {
    display: none;
  }
  .banners {
    max-width: 750px;
    padding-bottom: 50px;
    overflow-x: scroll;
  }

  &.dark {
    background: #131118;
    .preview-title {
      color: #fff;
    }
    .used-on-info {
      color: #fff;
    }
  }
  .preview-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .theme-selector {
    padding: 5px 10px;
    font-size: 14px;
    background: white;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 5px;
    &.dark {
      background: #1d1b21;
      color: #fff;
      border-color: #fff;
    }
    &:hover {
      text-decoration: none;
    }
  }
  #mobile-app-banner {
    position: unset;
    transform: unset;
    display: block;
  }
  .suggested-next-donate-banner {
    width: 450px;
  }
  .video-list-donate-banner {
    width: 278px;
  }

  .donate-warning-navbar {
    width: 1200px;
    display: block;
    position: static;
  }
  .donate-warning-navbar__watch {
    display: none;
  }
  &.dark {
    .donate-warning-navbar__main {
      display: none;
    }
    .donate-warning-navbar__watch {
      display: block;
    }
  }
}
.banner-edit-area {
  display: flex;
  justify-content: space-between;
  position: relative;
}

// Notifications
#manage-banner-app {
  .alert {
    position: fixed;
    top: 3.313rem;

    &.alert-dismissible {
      width: 31.25rem;
      z-index: 9999;
      right: 2rem;
      margin-top: 3.5rem;
      border-radius: 0.25rem !important;
      font-size: 0.875rem !important;
    }

    &.alert-success {
      border-width: 1px;
      text-align: left !important;
      border-color: #AAE9DD;
      color: #21A18A;
      background-color: #F2FFFD;
    }

    &.alert-error {
      border-width: 1px;
      text-align: left !important;
      border-color: #D35163;
      color: #FFFFFF;
      background-color: #4B131B;
      margin-top: 28px;

      .fill {
        fill: #FFFFFF;
      }
    }
  }

  .close-notification {

  }
}

// Banners
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#mobile-app-banner {
  position: fixed;
  bottom: 0;
  width:100vw;
  background: #fff;
  border-radius: 12px 12px 0 0;
  padding: 25px 15px;
  z-index: 10;
  max-width: 450px;
  left: 50%;
  transform: translateX(-50%);
  display: none;

  &.show {
    display: block;
    animation: fadeIn 1s ease-in;
  }
  .main-container {
    display: flex;
    gap: 15px;
    position: relative;
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    font-family: 'DM Sans', sans-serif;
    padding-right: 10px;
    margin-bottom: 5px;
  }
  .description {
    font-size: 14px;
    padding-right: 10px;
  }
  svg.close-banner {
    position: absolute;
    right: 0;
    top: -10px;
    fill: #A6A6A6;
    width: 24px;
    height: 24px;
  }
  .get-app-button {
    background: #6998AE;
    display: block;
    border-radius: 6px;
    color: #fff;
    font-size: 18px;
    padding: 10px 0;
    text-align: center;
    margin-top: 20px;
  }
}
.site-watch #mobile-app-banner {
  background: #2B2834;

  .title {
    color: #fff;
  }
  .description {
    color: #D1D3D4;
  }
  .app-logo-wrapper svg path {
    fill: #fff;
  }
}

/// Donate Modal ///
@import 'banners/donate_modal';
#banner-preview-area {
  .donate-modal__content {
    display: inline-block;
  }
  #donateModal .donate-modal__body {
   width: 530px;
 }
 #donateModal .modal-header {
   height: 48px;
 }
}


/// Donate banner ///
.suggested-next-donate-banner, .video-list-donate-banner {
  .main-container {
    display: flex;
    margin: 0 16px 18px;
    gap: 20px;
  }
  .text-block {
    width: 158px;
    height: 90px;
    border-radius: 8px;
    color: #F3F4F5;
    background-image: url("#{$path-to-img}/banners/donate_banner_background.svg");
    background-color: #41535B;
    background-position: center;
    background-size: 100% 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .title {
    font-family: DM Sans, sans-serif;
    font-size: 12px;
    font-weight: 500;
  }
  .description {
    font-family: DM Sans, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .donate-button {
    font-family: DM Sans, sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    background: #444050;
    width: 159px;
    padding: 7px 0;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
  }
}
.suggested-next-donate-banner.single {
  .text-block {
    background: none;
  }
  .main-container {
    background-image: url("#{$path-to-img}/banners/donate_banner_background.svg");
    background-color: #41535B;
    background-position: center;
    background-size: 100% 100%;
    border-radius: 8px;
  }
  .text-block {
    width: 210px;
  }
  .donate-button {
    padding: 12px 0;
  }
}

.suggested-next-donate-banner, .suggested-next-donate-banner.single {
  .text-block {
    background-size: cover;
  }
}
.video-list-donate-banner {
  .main-container {
    flex-direction: column;
  }
  .text-block {
    height: 90px;
    gap: 10px;
    padding: 15px;
    width: 100%;

    @include media-breakpoint-up(xl) {
      width: 246px;
    }

    @include media-breakpoint-up(sm) {
      height: 140px;
    }
  }
  .title {
    font-size: 18px;
    line-height: 18px;
  }
  .description {
    font-size: 16px;
  }
  .buttons-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
  .donate-button {
    width: 47%;
  }
  &.single {
    .donate-button {
      width: 100%;
    }
  }
}
.watch-landing-wrapper {
  .video-list-donate-banner {
    display: none;
    .text-block {
      height: 140px;
      width: 246px;
    }
  }

  .watch-category-section-wrapper:first-of-type {
    .video-list-donate-banner {
      display: initial;
    }
  }
  .watch-category-section-wrapper:nth-of-type(5n) {
    .video-list-donate-banner {
      display: initial;
    }
  }
}

// Articles landing

.articles-donate__question-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.articles-donate {
  position: relative;
  background: #F9F9F9;
  border-radius: 4px;
  border-left: solid #d18f42 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 30px;
  border-bottom: 1px solid #EAEDEE;
  width: 660px;
  height: fit-content;
}

.articles-donate__question {
  font-family: 'DM Sans', serif;
  font-style: normal;
  color: #323232;
  padding: 24px 24px 0 24px;
  display: flex;
  flex-direction: column;
}

.articles-donate__question-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  white-space: pre-line;
}

.articles-donate__buttons {
  position: relative;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.articles-donate__button-us,
.articles-donate__button-ca,
.articles-donate__button-explore-projects {
  width: 188px;
  height: 48px;
  border-radius: 8px 0;
  display: flex;
  justify-content: space-between;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 48px;
  cursor: pointer;
  margin-bottom: 8px;
  a {
    width: 100%;
    text-align: center;
  }
}

.articles-donate__button-us,
.articles-donate__button-ca {
  background: #6998ae;
  box-shadow: 0 2px 15px rgba(73, 73, 73, 0.2);

  a {
    color: #ffffff;
  }
}

.articles-donate__button-explore-projects {
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

  a {
    color: #6998ae;
  }
}

.articles-donate__question-description {
  p {
    margin-bottom: 0;
  }
}

