.hero-2-col {
  @extend .position-relative;

  $padding-bottom: 3.875rem; // 62px

  padding-bottom: $padding-bottom;

  @include media-breakpoint-up(md) {
    padding-top: 8rem; // 128px
  }

  @include media-breakpoint-up(lg) {
    padding-top: 10rem; // 160px
  }

  .row {
    @include max-width-xxl;
    @extend .flex-wrap-reverse;
  }

  .text-col {
    padding: 2.8125rem 1.5rem 1.875rem 1.5rem; // 45px 24px 30px 24px

    @include media-breakpoint-up(md) {
      padding-right: 4rem; // 64px
      padding-left: 4rem; // 64px
    }

    @include media-breakpoint-up(xl) {
      padding-top: 6.5625rem; // 105px
      padding-left: 9rem; // 144px
      padding-right: 5.75rem; // 92px
    }
  }

  .image-col {
    @extend .px-0;
    @extend .position-relative;
  }

  .heading {
    @extend .mb-4;
    @extend .mb-md-6;
    @extend .font-weight-bold;

    color: $black;
  }

  .description {
    @include media-breakpoint-up(md) {
      font-size: $font-size-lg;
    }

    p:last-of-type {
      @extend .mb-0;
    }
  }

  .cover {
    @extend .w-100;

    @include media-breakpoint-up(md) {
      position: absolute;
      right: 0;
      max-width: 41.75rem; // 668px
      z-index: 1;
    }

    @include media-breakpoint-down(sm) {
      @include object-fit-cover;

      max-height: 15.5625rem; // 249px
    }
  }

  .shape-container {
    color: $white;
    top: $padding-bottom;
  }

  $retina-delta: 0.3px;

  .shape {
    @extend .shape-top;

    @media (-webkit-min-device-pixel-ratio: 2) {
      bottom: -$retina-delta !important;
    }
  }

  .shape-img {
    @mixin shape-angle($size) {
      padding-bottom: $size;

      @media (-webkit-min-device-pixel-ratio: 2) {
        padding-bottom: calc(#{$size} + #{$retina-delta}) !important;
      }
    }

    @include media-breakpoint-down(md) {
      @include shape-angle($size: $padding-bottom);
    }

    @include media-breakpoint-up(lg) {
      @include shape-angle($size: 5.375rem); // 86px
    }
  }
}
