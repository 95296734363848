.topics-index {
  .hero-redesigned-short {
    .content-col {
      height: 234px;
    }

    .content-container {
      padding: 0;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      max-width: 717px;
      text-align: center;
    }
  }
}
