.episode-countdown {
  height: 530px;
  -webkit-backdrop-filter: blur(5px); /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
  backdrop-filter: blur(5px); /* Supported in Chrome 76 */

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-down(sm) {
    height: auto;
  }

  .live-in {
    font-size: 24px;
    font-weight: 500;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }
  .countdown {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .countdown-text {
    font-size: 14px;
    font-weight: 400;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .mobile-countdown-info {
    display: none;
    padding: 10px;

    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .mobile-info-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    width: 263px;
  }

  .mobile-countdown-buttons {
    display: none;

    @include media-breakpoint-down(sm) {
      display: flex;
      width: 100%;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }
  }

  .short-countdown-text {
    font-size: 16px;
    font-weight: 300;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  form.apply-passcode {
    display: block;
    margin-top: 20px;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    .form-inputs {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
    }
    .apply-passcode-error {
      display: flex;
      font-size: 12px;
      font-weight: 400;
      color: #D35163;
      max-width: 301px;
      height: 100px;
      margin: 9px auto 0;

      .hidden {
        visibility: hidden;
      }

      @include media-breakpoint-down(sm) {
        height: 40px;
      }

      .apply-passcode-error-text {
        text-align: left;
      }
    }

    input.passcode-input,
    input[type="submit"] {
      background-color: #444050;
      border-radius: 4px;
      border: none;
      height: 32px;
      font-size: 14px;
    }

    input.passcode-input {
      width: 220px;
      padding-left: 10px;
      color: #FFFFFF;
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #706C7A;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #706C7A;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: #706C7A;
      }

      &.error {
        border: 1px solid #D35163;
      }
    }

    input[type="submit"] {
      color: #FFFFFF;
      padding: 0 16px;
    }
  }
  .desc-not-authorized {
    max-width: 406px;
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
      display: flex;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.episode-countdown-text {
  width: 100%;
  height: 100%;
  background-color: rgb(21, 19, 27); /* Fallback color */
  background-color: rgba(21, 19, 27, 0.95); /* Black w/opacity/see-through */
  font-weight: bold;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;
}
.episode-countdown-buttons {
  display: flex;
  gap: 12px;
  align-items: baseline;
  justify-content: center;
  height: 100px;
  margin-top: 16px;
  margin-bottom: 16px;
  a {
    font-weight: 500;
    font-size: 14px;
    padding: 6px 16px;
    border-radius: 4px;
    border: none;
  }
  .sign-in {
    color: #6998AE;
    background-color: #FFFFFF;
  }
  .sign-up {
    background-color: #6998AE;
    color: #FFFFFF;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}
