//
// reboot.scss
//

html, body {
  overflow-x: hidden;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}
