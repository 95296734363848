%search-input {
  @extend .bg-transparent;
  @extend .rounded;
}

%search-input-border {
  border: 1px solid $search-form-color !important;
  outline: none;
}

@mixin search-input-clear-button {
  &:focus::-webkit-search-cancel-button {
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='#{encodeColor($search-form-color)}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
    width: 16px;
    height: 16px;
  }
}

@mixin search-input-autofill {
  &:-webkit-autofill {
    -webkit-text-fill-color: $search-form-color !important;
  }

  // to prevent auto-fill suggestions from styling the input according to browser styles
  &:-webkit-autofill:first-line {
    font-family: $font-family-sans-serif;
    color: $search-form-color;
  }
}

@mixin search-input-autofill-mobile {
  &:-webkit-autofill:first-line {
    font-size: $font-size-xs;

    @include media-breakpoint-up(md) {
      font-size: $font-size-sm !important;
    }
  }
}

%search-placeholder-element-color {
  color: $search-form-color;
  opacity: 0.75;
}
