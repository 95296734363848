.watch-topics-index {
  .grouped-topics-container {
    @extend .pt-md-7;

    padding-bottom: 3.75rem;
    padding-left: 1rem;
    padding-right: 1rem;

    column-gap: 2.4375rem;
    column-width: 21.125rem;

    @include media-breakpoint-between(md, lg) {
      column-count: 2;
    }

    @include media-breakpoint-up(xl) {
      column-count: 3;
    }
  }

  .topics-group {
    break-inside: avoid-column;
  }

  .group-title {
    @extend .text-white;
    @extend .font-weight-bold;
    @extend .text-uppercase;

    border-bottom: 1px solid $onyx;
  }

  .topics {
    @extend .py-3;
    @extend .pb-md-5;
  }

  .topic-wrapper {
    @extend .d-inline-block;
    @extend .d-md-block;
  }
}
