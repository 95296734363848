.search-results-page {
  @extend .pt-3;
  @extend .pt-md-5;
  @extend .pt-lg-7;

  .search-results-container {
    max-width: 1270px;
  }

  .watch-grid-container {
    @extend .pt-2;
  }

  .search-results-heading {
    @extend .text-center;

    font-size: $h4-font-size;

    @include media-breakpoint-up(md) {
      font-size: $h3-font-size;
    }
  }
}
