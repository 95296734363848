.watch-episode .suggested-episodes-container {
  &.hidden {
    display: none;
  }

  .cards-slider {
    @extend .position-relative;
  }

  .cards-container {
    overflow-y: scroll;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    @include media-breakpoint-up(xl) {
      height: 36.25rem;
    }
  }

  .suggested-next-card {
    @extend .col-md-6;

    margin-bottom: 1.125rem;
  }

  .special-episode-labels-container {
    margin: 0;
  }

  %scroll-button {
    @extend .position-absolute;
    @extend .w-100;
    @extend .d-none;
    @extend .d-xl-block;

    &:hover {
      cursor: pointer;
    }

    z-index: 1;
    height: 7.125rem;

    &.hidden {
      opacity: 0;
      // Using this instead of "display: none" because transition doesn't work
      // in the latter case.
      height: 0;
    }

    transition: opacity 150ms ease-in;
  }

  @mixin scroll-button-gradient($direction) {
    background: linear-gradient($direction, rgba(#131118, 0), #131118 70%);
  }

  .scroll-up-button {
    @extend %scroll-button;
    @include scroll-button-gradient(to top);

    top: 0;
  }

  .scroll-down-button {
    @extend %scroll-button;
    @include scroll-button-gradient(to bottom);

    bottom: 0;
  }

  %scroll-icon {
    @extend .position-absolute;

    left: 50%;
    transform: translateX(-50%);
  }

  .scroll-up-icon {
    @extend %scroll-icon;

    top: 1.625rem;
  }

  .scroll-down-icon {
    @extend %scroll-icon;

    bottom: 1.625rem;
  }

  .special-episode-labels-container {
    display: flex;
    margin: 0;
    gap: 5px;
  }
  .special-episode-label {
    font-size: 12px;
    font-weight: 400;
    padding: 4px 10px;
    border-radius: 6px;
    color: #fff;

    &.premiering {
      background: #d83f76;
    }
    &.exclusive {
      background: #309967;
    }
    &.live_in {
      background: #5a3cd2;
    }
  }
}

