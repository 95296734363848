.external-resource-widget {
  $angle-size: 3.1875rem; // 51px

  &.with-angle {
    margin-top: $angle-size;
  }

  .shape {
    @extend .shape-top;
  }

  .shape-img {
    padding-bottom: $angle-size;
  }

  .content-container {
    @extend .d-lg-none;

    padding: 3.1875rem 1.3125rem 0; // 51px 21px 0px
  }

  .content-container-lg {
    @extend .d-none;
    @extend .d-lg-block;
    @extend .mx-auto;

    padding-top: 4.375rem; // 70px
    max-width: 750px;

    .call-to-action-col {
      @extend .d-flex;
      @extend .flex-column;

      min-width: 300px;
      max-width: 500px;
    }

    .heading {
      @extend .text-left;

      margin-bottom: 1.375rem; // 22px
    }

    .description {
      @extend .p-0;
      @extend .text-left;
    }

    .resource-image {
      @extend .mb-0;

      max-width: 200px;
    }

    .image-col {
      @extend .ml-5;
    }

    .link-btn {
      @extend .ml-0;

      margin-top: 1.375rem; // 22px
    }
  }

  .heading {
    @extend .text-center;

    margin-bottom: 0.625rem; // 10px

    font-size: $h4-font-size;
    font-weight: $font-weight-bold;
    color: $black;
    line-height: 1.2;
  }

  .image-wrapper {
    @extend .mx-auto;

    padding-left: 2.5625rem; // 41px
    padding-right: 2.5625rem; // 41px
    max-width: 25rem; // 400px
  }

  .resource-image {
    @extend .w-100;

    margin-bottom: 0.625rem; // 10px
  }

  .description {
    @extend .text-center;
    @extend .font-size-sm;

    padding-left: 2.5625rem; // 41px
    padding-right: 2.5625rem; // 41px
  }

  .link-btn {
    @extend .btn;
    @extend .btn-primary;
    @extend .lift;
    @extend .d-block;
    @extend .mx-auto;

    width: 15.625rem; // 250px
    margin-top: 2.375rem; // 38px
  }
}
