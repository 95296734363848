.watch-speaker {
  .header {
    @extend .position-relative;
  }

  .header-container {
    @include max-width-xxl;
    @extend .d-md-flex;

    padding: 1.875rem 1.125rem;

    @include media-breakpoint-up(md) {
      padding-left: 1.875rem;
      padding-right: 1.875rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 2.5625rem 5.3125rem;
    }
  }

  .thumbnail-wrapper {
    margin-bottom: 1.375rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      padding-right: 1.875rem;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 2.3125rem;
    }
  }

  .thumbnail {
    @include object-fit-cover;
    @extend .d-block;
    @extend .mx-auto;

    width: 9.375rem;
    height: 9.375rem;

    @include media-breakpoint-up(md) {
      width: 11.25rem;
      height: 11.25rem;
    }
  }

  .full-name {
    margin-bottom: 0.875rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 1.25rem;
      font-size: 2.125rem;
    }
  }

  .description {
    @extend .text-white;
    @extend .mb-xl-0;

    margin-bottom: 1.125rem;
    font-size: $font-size-xs;
    line-height: 1.34;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-sm;
      line-height: 1.52;
    }
  }

  .topics {
    @extend .d-xl-none;
  }

  .topics-xl {
    @extend .d-none;
    @extend .d-xl-block;

    margin-left: 5.5rem;
    padding-top: 4.375rem;
    flex-basis: 14.375rem;
    flex-shrink: 0;
  }

  .content-container {
    padding-top: 2rem;

    @include media-breakpoint-up(lg) {
      padding-top: 2.625rem;
      padding-bottom: 1.5rem;
    }
  }

  .watch-grid-container {
    @extend .pt-0;
  }

  .watch-grid-title {
    margin-bottom: 0.75rem;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(lg) {
      margin-bottom: 1rem;
    }
  }
}
