.container-xl-bounded {
  @include media-breakpoint-up(xl) {
    max-width: $xxl-width !important;
    margin: 0 auto !important;
  }
}

@mixin max-width-xxl {
  max-width: $xxl-width;
  margin-left: auto;
  margin-right: auto;
}
