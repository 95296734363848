// TODO: extract header styles and generalizing together with watch_series_banner element

.watch-series {
  .container-store-items {
    padding: 32px;
  }
  #store-items-app {
    display: block;
  }
  .watch-series-header {
    background: radial-gradient(
      circle,
      $watch-landing-background-color 60%,
      #2b292f 92%
    );
  }

  .header-container {
    @include max-width-xxl;
    @extend .w-100;
    @extend .no-gutters;

    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .cover-col {
      @extend .d-none;
      @extend .d-md-block;
      @extend .position-relative;
    }

    .countdown-container {
      @extend .position-relative;
    }

    .cover {
      @include cover;
    }

    .content-wrapper {
      @extend .position-relative;
      @extend %page-banner-content-wrapper;

      // Apply z-index to make the series description,
      // the blurred background image, and the wider heading gradient background
      // play together nicely.
      z-index: 1;
    }

    .series-label-container {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #D18F42;
    }

    .label-text {
      letter-spacing: 4px;
    }

    .episodes-count {
      color: #D18F42;
      font-size: 14px;
      line-height: 29px;
    }

    .bullet {
      color: #D18F42;
    }

    .title {
      @extend .mb-0;

      @include media-breakpoint-up(lg) {
        font-size: 2.125rem;
        line-height: 1.23;
      }
    }

    .title-alt {
      font-size: $font-size-base;
      line-height: 1.36;
      color: $title-alt-color-lighter;

      @include media-breakpoint-up(lg) {
        line-height: 1.23;
      }
    }

    .description-sm {
      @include watch-text-base;
      @extend .d-md-none;
    }

    .description-md {
      @include watch-text-base;
      @extend .d-none;
      @extend .d-md-block;
      @extend .mt-2;
    }

    .speakers {
      .speaker-avatar {
        width: 24px;
        height: 24px;
        border: 0.5px solid #D1D3D4;
        border-radius: 50%;
      }

      .speaker-stack {
        margin-left: -12px;
      }

      .speaker-name {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #D1D3D4;
      }
    }

    .topics {
      @extend .mt-3;
    }
  }
}
