//
// position.scss
//

// Top

.top-left {
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
}

.top-right {
  top: 0 !important;
  right: 0 !important;
  transform: none !important;
}

.top-center {
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

// Bottom

.bottom-right {
  right: 0 !important;
  bottom: 0 !important;
  transform: none !important;
}

.bottom-left {
  bottom: 0 !important;
  left: 0 !important;
  transform: none !important;
}

.bottom-center {
  bottom: 0 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

// Responsive

@each $width, $widthValue in $container-max-widths {
  @include media-breakpoint-up($width) {

    .top-#{$width}-left {
      top: 0 !important;
      left: 0 !important;
      transform: none !important;
    }

    .top-#{$width}-right {
      top: 0 !important;
      right: 0 !important;
      transform: none !important;
    }

    .top-#{$width}-center {
      top: 0 !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }

    .bottom-#{$width}-left {
      bottom: 0 !important;
      left: 0 !important;
      transform: none !important;
    }

    .bottom-#{$width}-right {
      bottom: 0 !important;
      right: 0 !important;
      transform: none !important;
    }

    .bottom-#{$width}-center {
      bottom: 0 !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
  }
}
