@import 'sites/watch/variables';
@import 'sites/watch/shared/search_input';

// NOTE: don't wrap general navbar styles into .site-watch: it breaks styles because .navbar rules
// are defined in Bootstrap CSS files. Only wrap CSS customizations with .site-watch.
.navbar {
  @extend .navbar-expand-xl;
  @extend .fixed-top;
  @extend .d-block;
  @extend .p-0;

  // Fix for the last dropdown menu section jumping off-screen when opening the BS collapse
  // https://github.com/twbs/bootstrap/issues/23374#issuecomment-324747875
  max-height: 100%;

  @include media-breakpoint-up(lg) {
    overflow-y: visible;
  }

  font-weight: $font-weight-bold;
  transition: background-color 0.3s ease-in;

  .navbar-container {
    @extend .px-md-3;
    @extend .px-xl-8;
  }

  %navbar-mobile-row-padding {
    padding-left: 16px;
    padding-right: 16px;
  }

  .navbar-logo-container {
    @extend %navbar-mobile-row-padding;
    @extend .d-flex;
    @extend .w-100;
    @extend .w-xl-auto;
    @extend .px-xl-0;
  }

  .profile-logo-header {
    display: flex;

    &.black {
      .fill {
        fill: $black;
      }

      .profile-logo-title {
        color: $black;

        &:hover {
          color: $black;
        }
      }
    }

    .fill {
      fill: $white;
    }

    .profile-logo-title {
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }

  .profile-logo-title {
    margin-left: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
  }

  .login-welcome-tooltip__container {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
    display: flex;
    justify-content: center;
    position: absolute;
    padding: 16px 16px;
    top: 80px;
    right: 54px;
    background: #B56941;
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(98, 98, 101, 0.4);
    align-items: center;
    gap: 50px;
    color: white;
    stroke: white;
    .login-tooltip__title {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
    }

    .login-tooltip__close {
      cursor: pointer;

      .fill {
        fill: #FFFFFF;
      }
    }
  }
  .login-welcome-tooltip__container {
    display: none;
  }
  .login-tooltip__container {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 306px;
    padding: 16px;
    top: 80px;
    right: 54px;
    background: #B56941;
    border-radius: 8px;
    box-shadow: 0px 4px 30px rgba(98, 98, 101, 0.4);

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 45px;
      top: -28px;
      width: 0;
      height: 0;
      border: 14px solid transparent;
      border-bottom-color: #B56941;
    }
  }

  .login-tooltip__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
  }

  .login-tooltip__close {
    cursor: pointer;

    .fill {
      fill: #FFFFFF;
    }
  }

  .nav-link {
    transition: color 0.3s ease-in;
  }

  .navbar-home-link {
    @extend .navbar-brand;

    @include media-breakpoint-down(xs) {
      width: 120px;
    }

    svg {
      @extend .position-relative;

      top: -5px;
      fill: $white;
    }
  }

  .logo {
    transition: fill 0.3s ease-in;
  }

  .toggler-icon {
    transition: stroke 0.3s ease-in;
  }

  .search-form-inline {
    @extend .position-relative;
    @extend .d-flex;
    @extend .w-100;
    @extend .d-xl-none;
    @extend .font-size-xs;

    @include media-breakpoint-up(md) {
      font-size: $font-size-sm !important;
    }

    .navbar-toggler {
      @extend .ml-0;
      @extend .mr-auto;

      // search input comes 1st in the HTML markup to make the following sibling (~) CSS work
      // when hiding navbar toggler upon expanding the search input; to visually display the
      // navbar toggler 1st, we use Flexbox order tweaking
      order: -1;
    }

    .search-input {
      @extend %search-input;
      @extend .ml-auto;
      @extend .pt-1;
      @extend .pr-2;
      @extend .pl-3;
      @extend .border-transparent;
      @include search-input-clear-button;
      @include search-input-autofill;
      @include search-input-autofill-mobile;

      width: 24px;
      transition: width 0.2s ease-in-out;
      color: $search-form-color;
    }

    .search-input::placeholder {
      opacity: 0;
    }

    .search-input:not(:focus) {
      font-size: 0;
    }

    .search-input:focus {
      @extend %search-input-border;
      @extend .w-100;
    }

    .search-input:focus::placeholder {
      @extend %search-placeholder-element-color;
    }

    .search-input:focus ~ .navbar-toggler {
      @extend .d-none;
    }

    .search-input:focus ~ .search-toggler-icon {
      @extend .d-none;
    }

    .search-toggler-icon {
      @extend .position-absolute;

      top: 0.7rem; // .pt-2
      @include media-breakpoint-between(lg,xl) {
        top: 0.8rem;
      }
      right: 0.5rem;
      stroke: $white;
      pointer-events: none; // to click through to the search input
    }
  }

  &::before {
    @extend .d-none;
  }

  &.navbar-dark {
    background-color: transparent;

    .btn-donate {
      @extend .btn-light;
      @extend .mr-lg-4;
    }

    .toggler-icon {
      stroke: $white;

      path {
        fill: $white;
      }
    }

    // TODO: move usages of this mixin to the respective elements' stylesheet files
    @mixin black-navbar-content {
      .toggler-icon {
        stroke: $black;

        path {
          fill: $black;
        }
      }

      .logo {
        fill: $black;
      }

      .nav-link {
        color: $black;
      }
    }

    &.navbar-fill-bg {
      @include black-navbar-content;
    }

    &.navbar-hero-2-col {
      @include media-breakpoint-up(lg) {
        @include black-navbar-content;
      }
    }

    &.navbar-speaker-bio-summary {
      @include black-navbar-content;
    }
  }

  &.navbar-light {
    background-color: white;

    .logo,
    .unauthorized-profile-logo path {
      fill: $black;
    }

    .toggler-icon {
      stroke: $black;

      path {
        fill: $black;
      }
    }
  }

  .dropdown-header {
    @extend .font-size-xs;

    color: $subheadings-secondary;
  }

  .desktop-more-nav-item {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .nav-link-position {
    position: relative;
  }

  .navbar-dropdown-menu {
    margin-right: 0 !important;
    min-width: 37rem !important;
    background-color: #32303C;
    right: 0;
    border-radius: 0 0 1rem 1rem;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .more-navbar-block {
    max-width: 11.5rem;
    min-width: 9.5rem;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .navbar-sections-row.more {
    justify-content: space-between;
  }

  .mobile-navbar-nav {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  .more-navbar {
    font-family: 'DM Sans';
    font-style: normal;
    padding-top: -0.5rem;
    text-align: left;
    list-style-type: none;
  }

  .more-header-link-block {
    margin-left: 0 !important;
    padding-left: 0;
    padding-bottom: 1rem;
  }

  .more-header-link {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.125rem;
    color: #FFFFFF !important;

    &:hover {
      color: #8f8a9b !important;
    }
  }

  .more-header {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.25rem;
    color: #D1D3D4;
    padding-bottom: 1rem;
    text-transform: uppercase;
    font-family: 'DM Sans';
  }

  .dropdown-header-container {
    .dropdown-toggle-icon {
      color: #D1D3D4 !important;
    }
  }

  .nav-item-mobile-title {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 14px !important;
    line-height: 1.625rem !important;
  }

  .nav-item-mobile-link {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 0.875rem !important;
    line-height: 1.125rem !important;
  }

  .btn-donate {
    @extend .btn;
    @extend .btn-primary;
    @extend .d-xl-block;
    @extend .d-none;
    @extend .mr-lg-4;
  }

  .navbar-collapse .nav-link {
    @extend %navbar-mobile-row-padding;

    padding: 1.125rem 16px !important;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.6;

    .navbar-home-link svg {
      height: 30px;
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-lg;
  }
}

@media (max-width: 992px) {
  .profile-logo-header {
    display: none;
  }
}

.watch-arrow-right {
  display: none;

  @media (max-width: 1199.98px) {
    display: block;
    margin-left: auto;
  }
}

.watch-sub-menu-block {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  z-index: 20000;
  background-color: #131118;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .header {
    display: flex;
    flex-direction: row;
    height: 48px;
    align-items: center;
    border-bottom: 1px #2B2834 solid;

    .back-btn {
      display: flex;
      justify-content: start;
      margin-right: 8px;
      margin-left: 16px;
      cursor: pointer;
    }

    .title {
      display: flex;
      margin-right: auto;
      font-family: DM Sans, serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #fff;
    }
  }

  .nav-block {
    display: flex;
    flex-direction: column;

    .nav {
      list-style: none;
      display: flex;
      flex-direction: column;
      margin-left: 0 !important;

      .nav-item {
        padding: 16px 163px 16px 25px;
        height: 52px;
        background-color: #32303C;

        .nav-link {
          font-family: DM Sans, serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: #d1d3d4;
          padding: 0 !important;
        }

        .dropdown {
          display: none !important;
        }
      }

      .nav-item:hover {
        background-color: #1C1923;
      }

      .nav-item:first-of-type {
        display: none;
      }
    }
  }
}

.navbar-dropdown-menu {
  .nav-item.dropdown {
    &:hover {
      background-color: #32303C;
    }
  }
}

.nav-item-icon {
  position: relative;
  .username {
    background: #8F8A9B;
    border-radius: 50%;
    color: white;
    width: 32px;
    text-align: center;
    font-weight: 400;
  }
}

#profile-dropdown {
  cursor: pointer;
  position: relative;
}
#profile-dropdown-menu {
  margin-left: -270px;
  min-width: unset;
  width: 300px;
  top: 50px;
  padding: 10px;
  background: #2b2933;
  border-radius: 0 0 1rem 1rem;

  .dropdown-item {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 5px 15px;
    border-radius: 8px;
    color: white;
    height: 55px;
    &:hover {
      background-color: #444050;
      color: white;
    }
    .item-icon-wrapper {
      width: 40px;
    }
    .item-icon {
      margin: auto;
      display: block;
      width: 24px;
      height: 24px;
      stroke: white;
      &.username {
        background: #8F8A9B;
        border-radius: 50%;
        color: white;
        text-align: center;
        width: 32px;
        height: unset;
        padding: 3px 0;
      }
    }

    .item-info {
      font-size: 16px;
      font-weight: 500;
    }
    .user-email {
      font-size: 12px;
      font-weight: 400;
    }
  }
}