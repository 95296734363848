@import 'application/navbar_mixins';

// Common base styles (for all Alchemy sites)

.watch-landing-sub-menu {
  .nav {
    list-style: none;
    margin-bottom: 0;
    margin-left: 2.75rem; // 44px
    padding-left: 0;
  }

  .nav-item {
    display: inline-block;
    margin-right: 1.5rem; // 24px
    padding-top: 0.75rem; // 12px
    padding-bottom: 0.75rem; // 12px

    @include media-breakpoint-up(md) {
      margin-right: 3.563rem; // 57px
      padding-top: 1rem; // 16px
      padding-bottom: 1rem; // 16px
    }
  }

  .nav-link {
    font-weight: $font-weight-bold;
    color: #b5b5b5;
    padding: 0;

    @include hover-focus-active {
      color: $white;
    }
  }
}

// Mobile styles

%main-menu-watch-sub-menu-mobile {
  @extend .mt-2;

  .nav {
    @extend .d-block;
    @extend .ml-4;
  }

  .nav-item {
    @extend .py-2;

    display: list-item;
  }

  .nav-item:nth-child(n + 2) {
    @extend .mr-4;
  }

  .nav-link {
    @extend .pl-5;
    @extend .font-size-sm;
    @extend .font-weight-normal;
  }

  .nav-item.active {
    .nav-link {
      @extend .text-white;
      font-weight: $font-weight-bolder;
    }
  }

  .home-link {
    @extend .d-none;
  }
}

// hide mobile search form from the Watch sub-menu mobile item that is embedded in the main menu
// on mobile (for both Main and Watch sites)
.watch-landing-sub-menu.embedded {
  .search-form {
    @extend .d-none;
  }
}
