// TODO: extract header styles and generalizing together with watch_series_banner element

.autoscroll-button-container {
  right: 1rem;
  bottom: 1rem;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  height: 1.5rem;
}

.autoscroll-text {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4rem;
  margin-right: 0.75rem;
  color: $white;
}

.watch-series-banner {
  @extend .mb-4;

  .slider {
    min-height: 420px;
  }

  .slider-container {
    @include max-width-xxl;
  }

  // add bottom space for page dots only if there's multiple slides
  .slider.multiple .action-buttons {
    @extend .mb-6;
  }

  [data-flickity]:not(.flickity-enabled) {
    display: initial;

    .watch-series-banner-slide:nth-child(n + 2) {
      display: none !important;
    }
  }

  .flickity-page-dots {
    @extend .position-absolute;
    bottom: 13px;
  }

  .flickity-page-dots .dot {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.6;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

    &.is-selected {
      background-color: $white;
      opacity: 1;
    }
  }

  .flickity-button {
    @extend .d-none;
    @extend .d-lg-block;

    background: rgba(11, 9, 15, 0.4);
    box-shadow: 0 0.25rem 2.5rem rgba(167, 167, 167, 0.15);

    &:not(:disabled):not(.disabled) {
      @include hover-focus-active {
        background-color: rgba(11, 9, 15, 0.7);
      }
    }
  }

  .flickity-viewport {
    transition: height 0.1s;
  }
}
