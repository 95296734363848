.speakers-full {
  display: flex;
  flex-wrap: wrap;

  .speaker {
    width: auto;
    flex: 0 0 auto;
    margin-right: 12px;
    margin-top: 8px;
    padding-right: 12px;
  }

  .speaker-name {
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
  }

  .speaker-avatar {
    width: 24px;
    height: 24px;
    border: 0.5px solid #D1D3D4;
    border-radius: 0.75rem;
    object-fit: cover;
  }
}

.speakers-short {
  margin-top: 4px;
  margin-bottom: 4px;

  .speaker-avatar {
    width: 24px;
    height: 24px;
    border: 0.5px solid #D1D3D4;
    border-radius: 50%;

    &:not(:first-child) {
      margin-left: -12px;
    }
  }

  .speaker-name {
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #D1D3D4;
    overflow: hidden;
  }
}
