@import 'sites/watch/shared/_page_top_banner';

// TODO: extract header styles and generalizing together with watch_series_banner element

.watch-category-header {
  @include watch-page-header-background;
  @extend .w-100;
}

.banner-wrapper {
  @include max-width-xxl;

  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .cover-col {
    @extend .d-none;
    @extend .d-md-block;
    @extend .position-relative;
  }

  .cover {
    @include cover;
  }

  .content-wrapper {
    @extend %page-banner-content-wrapper;
  }

  .text-content-wrapper {
    position: relative; // to make z-index work
    z-index: 2; // to place text above semi-transparent overlay
  }

  .name {
    margin-bottom: 0.875rem;

    @include media-breakpoint-up(lg) {
      font-size: 2.125rem;
    }
  }

  .description-sm {
    @include watch-text-base;
    @extend .d-md-none;
  }

  .description-md {
    @include watch-text-base;
    @extend .d-none;
    @extend .d-md-block;

    margin-top: 2.1875rem;
  }

  .topics {
    @extend .mt-md-0;

    margin-top: 1.125rem;
  }

  // Overriding default styles to make the category description,
  // the blurred background image, and the wider heading gradient background
  // play together nicely.
  .svg-blur {
    z-index: 0;
  }

  .svg-blur-overlay {
    z-index: 1; // to place semi-transparent overlay above the filter-blurred background image
  }
}
