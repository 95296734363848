.flickity-resize .watch-popular-series-section-slide {
  min-height: 100%;
}

// TODO: this class serves an intermediate layer between Flickity slider and .watch-entry and it's
// a good thing - Flickity manipulates the position and it should be separated from .watch-entry.
// We should refactor all the Flickity sliders that use .watch-entry directly as their slides and
// introduce such an intermediate layer.
.watch-popular-series-section-slide {
  @extend .d-inline-block;
  @extend .rounded-top-left;
  @extend .rounded-bottom-right;

  margin-right: 1.75rem;
  width: 16.875rem;
  min-height: 17.5rem;
  vertical-align: top;
  background-color: $secondary-bg;

  .thumbnail {
    @extend .rounded-top-left;

    height: 9.625rem;
  }

  .entry-content {
    // applying this padding here and not to the parent because of the whole-card link spanning
    // the .watch-entry .entry-content: .entry-content should span the entire parent
    @extend .pb-3;
  }

  .entry-textual-content {
    @extend .px-3;
  }
}
