// This solution is inspired by https://inclusive-components.design/cards/#thepseudocontenttrick
.watch-entry-link {
  color: inherit;

  @include hover-focus-active {
    color: inherit;
  }

  &::after {
    @extend .position-absolute;

    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
