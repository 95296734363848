.watch-entry {
  // need to have intermediate "position: relative" wrapper because .watch-entry's position
  // is managed by the grid layout JS
  .entry-content {
    @extend .position-relative;
  }

  .thumbnail {
    @include object-fit-cover;
    @extend .w-100;
    @extend .mb-1; // 8px

    height: $entry-card-thumbnail-height;
  }

  .title-container {
    @extend .mb-1;

    line-height: 1.08;
  }

  %title {
    font-size: $font-size-xs;
    font-weight: $font-weight-bolder;
    line-height: 1.28;
  }

  .title {
    @extend %title;
    @extend .h3; // for SEO purposes, these titles should not be HTML headings
    @extend .mb-0;
  }

  .title-alt {
    @extend %title;
    @extend .mt-1;

    color: $title-alt-color;
  }

  .footnote {
    @extend .mb-1;

    font-size: $font-size-xs;
    line-height: 1.28;
    color: $quick-silver;
  }

  .topic-link {
    // To make it clickable through the internal "whole-card" link, we need to use both position and
    // z-index. Just "position: relative" won't work because Topic links are located before
    // the title link in the HTML.
    @extend .position-relative;

    z-index: 1;
  }

  .special-episode-labels-container {
    display: flex;
    margin: 6px 0 10px;
    gap: 5px;
    position: relative;
  }
  .special-episode-label {
    font-size: 12px;
    font-weight: 400;
    padding: 4px 10px;
    border-radius: 6px;
    color: #fff;
    z-index: 5;

    &.premiering {
      background: #d83f76;
    }
    &.exclusive {
      background: #309967;
    }
    &.live_in {
      background: #5a3cd2;
    }

    .special-episode-tooltip-wrapper {
      .special-episode-tooltip {
        display: none;
        position: absolute;
        bottom: 50px;
        left: -15px;
        background: #2b2834;
        width: 276px;
        max-width: 48vw;
        padding: 16px 12px 20px 16px;
        border-radius: 8px;
        border: 1px;
        border: 1px solid #6998ae;
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -28px;
          width: 0;
          height: 0;
          border: 14px solid transparent;
          border-top-color: #6998ae;
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -27px;
          width: 0;
          height: 0;
          border: 14px solid transparent;
          border-top-color: #2b2834;
        }
      }
    }
  }
  .special-episode-label:hover {
    .special-episode-tooltip {
      display: block;
    }
  }
}
