// TODO: split this file up between "components" corresponding to isolated blocks that this menu
// part consists of. Extract shared definitions into application/navbar_mixins.
// https://coda.io/d/Amazing-Discoveries-shared_daD69i5U8P9/Tech-debt-sink_suy3x#Tech-debt-tasks_tuJWy/r154

@import 'application/navbar_mixins';

.navbar-nav {
  .dropdown {
    .dropdown-toggle::after {
      content: '';
    }

    &.show {
      .dropdown-toggle-icon {
        transform: rotate(180deg);
      }
    }

    .dropdown-toggle-icon {
      @extend .d-none;

      color: $gray-500;
      height: 24px;
      width: auto;
    }
  }
}

.navbar-dropdown {
  @extend .dropdown;
}

.dropdown-toggle-btn {
  @extend .btn;
  @extend .btn-circle;
  @extend .mr-0;
  @extend .mr-lg-4;

  &:not(:disabled):not(.disabled) {
    &:active:focus {
      box-shadow: none;
    }

    &:active {
      box-shadow: none;
    }
  }
}

.navbar-dropdown-menu {
  @extend .dropdown-menu-right;
  @extend .mr-7;
  @extend .px-0; // TODO: remove when Login/Signup is back https://www.pivotaltracker.com/story/show/174486185
}

// TODO: remove when Login/Signup is back https://www.pivotaltracker.com/story/show/174486185
.navbar-dropdown-menu.dropdown-menu {
  min-width: 40rem;
}

.navbar-sections-row {
  @extend .no-gutters;
}

.dropdown-header-container {
  @extend .font-size-xs;
  @extend .w-100;
  @extend .d-flex;

  color: $subheadings-secondary;
  font-weight: $font-weight-bolder;
  letter-spacing: 0.18em;

  .dropdown-header {
    @extend .mr-auto;
    @extend .mb-0;
    @extend .text-uppercase;
  }

  .icon {
    @extend .pr-1;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.nav {
  .dropdown-link {
    @extend .font-size-sm;

    color: $black;
  }

  @include media-breakpoint-down(md) {
    margin-left: 35px;
  }

  @include media-breakpoint-up(lg) {
    margin-left: 60px;
  }
}

.dropdown-menu-container {
  .navbar-nav {
    padding-left: $grid-gutter-width * 0.5; // to compensate right padding of sections' .col classes

    &:first-child {
      @extend .mt-0;
    }

    .dropdown-menu {
      @extend .mt-0;

      padding-top: 4px;
    }

    .nav-link.dropdown-toggle {
      @extend .px-5;
    }
  }
}

.navbar-collapse.collapse.show .navbar-nav .dropdown {
  .dropdown-toggle-icon {
    @extend .d-block;
  }
}

.dropdown-menu {
  min-width: 55rem;
}

.dropdown-item,
.dropdown-link {
  white-space: normal;
}

.mobile-dropdown-menu {
  @extend .dropdown-positioner;

  .dropdown-menu {
    @extend .pt-0;
    @extend .dropdown-menu-xl;

    padding-bottom: 1rem;
  }
}

.desktop-dropdown-menu {
  @extend .nav;
}
