.site-watch {
  @include watch-page-common-styles;
}

#study-tools-app {
  @extend .d-none;
  @extend .d-lg-flex;
}

#flash-notifications-app {
  display: flex;
}

.mobile-study-tools-block {
  @extend .d-block;
  @extend .d-lg-none;

  #mobile-video-resources-app {
    @extend .d-block;
  }

  .study-tools-mobile__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
    width: 100%;
    padding: 2rem;
    margin-bottom: 1.5rem;
    overflow: hidden;
    background: rgba(32, 29, 38, 0.63);
    flex-direction: column;
    gap: 14px;

    &:before {
      background-image: url(#{$path-to-img}/watch/mobile-study-tools.png);
      background-size: cover;
      filter: blur(30px);
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .study-tools-mobile__content {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    color: $white;
    max-width: 20rem;
    filter: none;
  }

  .study-tools-mobile__button {
    display: flex;
    justify-content: center;
    width: 342px;
    height: 40px;
    padding: 9px 16px 9px 16px;
    gap: 10px;
    border-radius: 6px;
    background-color: #6998AE;
    box-shadow: 0 1px 10px 0 #1D202326;
    font-family: DM Sans, serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: white;
  }

  .study-tools-mobile__title {
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    border-top: 1px solid #2B2834;
  }
}
