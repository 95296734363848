.alert {
  @extend .position-relative;
  @extend .mb-0;
  // to avoid overlapping the 'close' button
  @extend .pr-8;
  @extend .pr-md-9;
  @extend .font-size-xs;
  @extend .text-center;

  border-radius: 0;

  @include media-breakpoint-up(md) {
    font-size: $font-size-sm !important;
  }

  a {
    color: $white;
    text-decoration: underline;
  }

  .close {
    @extend .position-absolute;

    top: 50%;
    transform: translateY(-50%);
    right: 0.875rem;

    @include media-breakpoint-up(md) {
      right: 1.5rem;
    }
  }
}
