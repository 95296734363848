.countdown {
  display: flex;
  gap: 8px;
  .countdown-item {
    display: block;
    .countdown-top-row {
      font-size: 48px;
      color: #D18F42;
      text-align: center;

      @include media-breakpoint-down(sm) {
        font-size: 32px;
      }
    }
    .countdown-bottom-note {
      color: #F3F4F5;
      font-size: 16px;
      text-align: center;
    }
  }
}