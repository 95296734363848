%breadcrumb-base {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 0;

  .separator {
    margin: 0 0.25rem;
  }
}

.breadcrumbs-main {
  @extend .d-none;
  @extend .d-sm-block;
  @include max-width-xxl;
  display: block !important;
  top: 5rem;

  .breadcrumb {
    color: #a6a6a6;
    @extend %breadcrumb-base;
    padding-left: 0;
    padding-right: 0;
    background: none;

    a {
      color: #a6a6a6;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include media-breakpoint-down(xs) {
        max-width: 100px;
      }

      &.active {
        color: #323232;

        @include media-breakpoint-down(xs) {
          max-width: 177px;
        }
      }
    }
  }
}

.breadcrumbs-watch {
  @extend .d-none;
  @extend .d-sm-block;
  .breadcrumb {
    @extend %breadcrumb-base;
    background: #131118;
    color: #5C5C5C;
    padding-left: 2.5rem;

    a {
      color: #5C5C5C;

      &.active {
        color: $white;
      }
    }
  }
}

.breadcrumbs-user {
  @extend .d-none;
  @extend .d-sm-block;
  .breadcrumb {
    background: $white;
    color: #a6a6a6;
    @extend %breadcrumb-base;
    padding-left: 3rem;

    a {
      color: #a6a6a6;

      &.active {
        color: #323232;
      }
    }
  }
}
