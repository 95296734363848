//
// docs.scss
//

// Body

.docs-body {
  padding-top: 109px;
}


// Breadcrumb

.docs-breadcrumb {
  padding-top: .25rem;
  padding-bottom: .25rem;
  background-color: $black;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}


// Sidenav

.docs-sidenav {
  overflow-y: auto;

  @include media-breakpoint-up(lg) {
    position: fixed;
    top: 109px;
    max-height: calc(100vh - 109px);
  }
}

.docs-sidenav-right {
  right: 0;
}


// Card

.docs-card {
  border-radius: $border-radius-sm;
  box-shadow: none;
}

.docs-card > *:first-child {
  border-top-left-radius: $border-radius-sm;
  border-top-right-radius: $border-radius-sm;
}

.docs-card > *:last-child {
  border-bottom-left-radius: $border-radius-sm;
  border-bottom-right-radius: $border-radius-sm;
}

.docs-card > .card-body {
  border: $border-width solid $border-color;
}

.docs-card > .card-footer {
  font-size: $font-size-xs;
  background-color: $dark;
}
