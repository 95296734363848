.watch-page-header {
  @extend .pt-5;
  @extend .pt-lg-8;

  .heading-container {
    @extend .text-center;
    @extend .mb-2;
    @extend .mb-md-4;
  }

  .heading {
    @extend .mb-0;

    font-size: 1.75rem;

    @include media-breakpoint-up(md) {
      font-size: 1.875rem;
    }
  }

  .footnote {
    color: $quick-silver;
    line-height: 1.23;

    @include media-breakpoint-up(md) {
      font-size: 1.375rem;
    }
  }

  .description {
    @extend .mx-auto;
    @extend .text-white;
    @extend .pb-2;

    max-width: 46rem;
    font-size: $font-size-xs;
    line-height: 1.52;

    @include media-breakpoint-up(md) {
      font-size: $font-size-sm;
    }
  }
}
