.alert {
  @extend .position-fixed;
  top: 3.313rem;

  &.alert-dismissible {
    width: 31.25rem;
    z-index: 9999;
    right: 2rem;
    margin-top: 3.5rem;
    border-radius: 0.25rem !important;
    font-size: 0.875rem !important;

    @include media-breakpoint-down(md) {
      position: relative !important;
      width: 100%;
      margin-top: 0;
      right: 0;
    }
  }

  &.alert-success {
    border-width: 1px;
    text-align: left !important;
    border-color: #AAE9DD;
    color: #21A18A;
    background-color: #F2FFFD;
  }

  &.alert-info {
    border-width: 1px;
    text-align: left !important;
    border-color: #C7D7F1;
    color: #3E6CB7;
    background-color: #EFF5FF;
  }

  &.alert-danger {
    border-width: 1px;
    text-align: left !important;
    border-color: #D35163;
    color: #FFFFFF;
    background-color: #4B131B;
    margin-top: 28px;

    .fill {
      fill: #FFFFFF;
    }
  }
}

.close-notification {
  @extend .pr-0;
}
