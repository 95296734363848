@import '~videojs-font/scss/videojs-icons';

.vjs-episode-player.video-js {
  $vjs-control-bar-height: 45px;

  .vjs-poster {
    background-size: cover;
  }

  .vjs-control-bar {
    @include transparent-background;

    height: $vjs-control-bar-height;
    padding-left: 0.84em;
    padding-right: 0.84em;
    font-family: $font-family-base;
    font-size: $font-size-xxs;

    @include media-breakpoint-up(md) {
      padding-left: 2em;
      padding-right: 2em;
      font-size: $font-size-xs;
    }

    .vjs-button {
      .vjs-icon-placeholder::before {
        transform: scale(1) !important;
        position: static; // for nicer and automatic placement in the control bar layout
      }
    }

    .vjs-control {
      width: 3em;
    }

    .vjs-play-control {
      width: calc(4em - 5px);

      .vjs-icon-placeholder::before {
        font-size: 2rem; // 32px
        line-height: 1;
      }
    }

    .vjs-menu {
      font-size: $font-size-xxs;
      color: $quick-silver;

      .vjs-menu-content {
        font-family: $font-family-base;
        border-radius: 0.5em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        background-color: rgba(#1c1923, 0.9);
      }

      .vjs-menu-item.vjs-selected {
        @extend .text-white;

        background-color: $onyx;
      }

      .vjs-menu-content {
        max-height: none;
      }
    }

    .vjs-time-control {
      line-height: $vjs-control-bar-height;
    }

    .vjs-volume-panel {
      &.vjs-volume-panel-horizontal {
        transition: width 0.4s;

        &.vjs-hover,
        &.vjs-slider-active {
          width: 8.5em;
        }
      }
    }

    .vjs-volume-bar {
      margin-top: 1.25rem; // 20px
      margin-bottom: 1.25rem; // 20px
    }

    .vjs-slider-horizontal .vjs-volume-level:before {
      top: -0.2rem;
    }

    .vjs-progress-control {
      @extend .position-absolute;
      @extend .w-100;

      bottom: 0;
      left: 0;
      height: 4px;
      flex: none;
      z-index: 1;

      .vjs-progress-holder {
        @extend .mx-0;
      }
    }
    .vjs-slider {
      background-color: rgba(209, 211, 212, 0.80);
    }
    .vjs-play-progress {
      background-color: #D18F42;
    }
    .vjs-play-progress::before {
      content: '';
    }

    .vjs-current-time {
      @extend .d-block;
      @extend .px-0;
    }

    .vjs-remaining-time {
      @extend .d-none;
    }

    .vjs-duration {
      @extend .d-block;
      @extend .px-0;
    }

    .vjs-time-divider {
      @extend .d-block;
      @extend .px-0;
      @extend .text-center;

      min-width: 1em;
    }

    .vjs-playback-rate {
      $vjs-playback-control-width: 3.5em;
      &.vjs-control {
        width: $vjs-playback-control-width;
      }

      .vjs-playback-rate-value {
        @extend .font-weight-bolder;

        font-family: $font-family-base;
        line-height: 2.5;

        @include media-breakpoint-up(md) {
          font-size: 1.29em;
        }
      }

      $vjs-playback-menu-width: 9.29em;
      .vjs-menu {
        width: $vjs-playback-menu-width;
        left: -($vjs-playback-menu-width * 0.5 - $vjs-playback-control-width *
              0.5);
      }
    }

    .vjs-quality-selector {
      .vjs-menu-button {
        @extend .vjs-icon-cog;

        font-size: 1.25rem; // 20px
      }

      .vjs-icon-placeholder {
        @extend .d-none;
      }
    }
  }

  .vjs-big-play-button {
    width: 2em;
    height: 2em;
    line-height: 1.85em;
    border-radius: 1em;
  }
}

:not(.vjs-has-started) {
  .vjs-poster {
    @extend .d-none;
  }

  .vjs-control-bar {
    @extend .d-flex;
  }
}

.vjs-control-bar {
  .vjs-button {
    &.skip-back {
      .vjs-icon-placeholder::before {
        content: '' !important;
        transform: scale(0.8) !important;
        -ms-transform: scale(0.8) !important;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1.5em' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cstyle%3Esvg%7Bfill:%23fafafa%7D%3C/style%3E%3Cpath d='M125.7 160H176c17.7 0 32 14.3 32 32s-14.3 32-32 32H48c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32s32 14.3 32 32v51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z'/%3E%3C/svg%3E");
        rotate: none;
      }
    }
    &.skip-forward {
      .vjs-icon-placeholder::before {
        content: '' !important;
        transform: scale(0.8) !important;
        -ms-transform: scale(0.8) !important;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1.5em' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cstyle%3Esvg%7Bfill:%23ffffff%7D%3C/style%3E%3Cpath d='M386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H464c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0s-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3s163.8-62.5 226.3 0L386.3 160z'/%3E%3C/svg%3E");
      }
    }
  }
}
