.footer {
  .nav-heading {
    @extend .h6; // for SEO purposes, these elements should not be HTML headings
    @extend .mb-3;
    @extend .d-block;
    @extend .text-uppercase;
    @extend .text-white;

    font-size: $font-size-xs;
  }

  .nav {
    @extend .list-unstyled;
    @extend .ml-0;

    flex-direction: column;
  }

  .nav-item {
    @extend .mb-2;
  }

  .nav-link {
    @extend .text-white-60;

    padding: 0;
    display: inline;
  }
}
