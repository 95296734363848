.site-main {
  .topics-by-model {
    .group-title {
      color: #323232;
      border-bottom: 1px solid #EAEDEE;
    }
  }

  .topics_by_model__topic {
    background: #FFFFFF;
    border: 1px solid #D1D3D4;
  }

  .topics_by_model__topic-link {
    color: #5C5C5C;
  }
}

.site-watch {
  .topics-by-model {
    .group-title {
      color: #FFFFFF;
      border-bottom: 1px solid #393542;
    }
  }

  .topics_by_model__topic {
    background: #3B3946;
    border: 1px solid #444050;
  }

  .topics_by_model__topic-link {
    color: #D1D3D4;
  }
}

.topics-by-model {
  .grouped-topics-container {
    margin-top: 35px;
    @extend .pt-md-7;

    padding-bottom: 60px;
    padding-left: 16px;
    padding-right: 16px;

    column-gap: 39px;
    column-width: 338px;

    @include media-breakpoint-between(md, lg) {
      column-count: 2;
    }

    @include media-breakpoint-up(xl) {
      column-count: 3;
    }
  }

  .topics-group {
    break-inside: avoid-column;
  }

  .group-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
  }
}

.topics_by_model__topics {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 34px;
}

.topics_by_model__topic {
  margin-top: 10px;
  width: fit-content;
  padding: 0 10px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topics_by_model__topic-link {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
