@mixin object-fit-cover {
  object-fit: cover;
  font-family: 'object-fit: cover;'; // IE11
}

@mixin cover {
  @include object-fit-cover;
  @extend .position-absolute;
  @extend .d-block;
  @extend .w-100;
  @extend .h-100;

  top: 0;
  left: 0;
}
