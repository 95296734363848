.watch-episode {
  .engagement-block {
    display: flex;
    gap: 8px;
  }

  .engagement-item {
    font-size: 1rem;
    position: relative;
    a {
      vertical-align: middle;
      color: #FFFFFF;
      white-space: nowrap;

      &.disabled-link {
        opacity: 0.30000001192092896;
        cursor: not-allowed;
      }
    }

    .engagement-icon {
      &:hover {
        rect {
          fill: #3B3946;
        }
      }
    }

    .engagement-title {
      line-height: 1.25rem;
      font-weight: $font-weight-bold;
      font-size: 1rem;
      vertical-align: middle;
    }
  }

  .desktop-engagement-block {
    @extend .d-none;
    @extend .d-md-block;
    @extend .text-right;

    %btn-engage {
      @extend .mx-1;

      padding: 0.625rem 0.5625rem;
    }

    .share-widget .btn-share {
      @extend .mr-0;
    }

    .share-widget .dropdown-menu {
      right: 0;
    }

    .audio-widget .dropdown-menu {
      left: auto;
      right: 0.25rem;
    }
  }

  .mobile-engagement-block {
    @extend .d-none;
    @extend .pb-4;
    @extend .text-center;
  }

  %engagement-widget {
    @extend .position-relative;
    @extend .d-inline-block;

    .dropdown-menu {
      @extend .mt-2;
      @extend .text-white;

      right: 0.5rem;
      min-width: 13rem;
      padding: 1.125rem;
      background-color: #2B2834;
      border-radius: 0.5rem;
    }

    .dropdown-item {
      padding: 0.125rem 0;
      color: $white;
      cursor: pointer;

      $icon-color: $white;

      i, svg path {
        fill: $icon-color;
        stroke: none;
      }

      &.copy_current_url svg path {
        fill: none;
        stroke: $icon-color;
      }

      @include hover-focus-active {
        color: darken($color: $white, $amount: 20%);

        .feather-code {
          fill: none;
          stroke: $white;
          color: $white;
        }
      }
    }
  }

  .audio-widget {
    @extend %engagement-widget;

    .dropdown-menu {
      left: 0.5rem;
      min-width: 12.5rem;
    }

    .icon {
      width: 1.75rem;
    }
  }

  .share-widget {
    @extend %engagement-widget;
  }
}
.orange-tutorial-step {
  position: absolute;
  display: flex;
  visibility: hidden;
  transition: visibility 0s, opacity 0.3s linear;
  background-color: #B56941;
  box-shadow: 0 4px 30px rgba(98, 98, 101, 0.4);
  bottom: 40px;
  z-index: 3;
  width: 278px;
  border-radius: 6px;
  padding: 20px 18px;
  left: -20px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    right: 19px;
    left: 16px;
    bottom: -28px;
    width: 0;
    height: 0;
    border: 14px solid transparent;
    border-top-color: #B56941;
  }

  .orange-tutorial-step__text {
    color: white;
    text-align: left;
  }

  .orange-tutorial-step__close {
    cursor: pointer;
    margin: auto;

    .fill {
      fill: #FFFFFF;
    }
  }
}

.tutorial-step {
  position: absolute;

  border: 1px solid #6998ae;
  box-shadow: 0 4px 30px rgba(23, 24, 28, 0.6);
  background: #2b2834;

  border-radius: 6px;
  padding: 16px 12px 20px 16px;

  z-index: 999;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 19px;
    right: 0;
    top: -28px;
    width: 0;
    height: 0;
    border: 14px solid transparent;
    border-bottom-color: #6998ae;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 19px;
    right: 0;
    top: -27px;
    width: 0;
    height: 0;
    border: 14px solid transparent;
    border-bottom-color: #2b2834;
  }
}

.tutorial-step[data-step='1'] {
  width: 265px;
  top: 45px;
  left: -24px;
}

.tutorial-title {
  color: #ffffff;
  text-align: left;
}

.tutorial-actions {
  display: flex;
  justify-content: left;
  margin-top: 16px;
}

.tutorial-action {
  padding: 7px 16px;
  background: #444050;
  box-shadow: 0 2px 10px rgba(73, 73, 73, 0.1);
  border-radius: 4px;
  margin-right: 8px;
  font-size: 14px;
  &.active {
    color: #ffffff;
    cursor: pointer;
  }
  &.inactive {
    color: #8f8a9b;
    cursor: not-allowed;
  }
}

.transcript-tooltip {
  position: absolute;
  visibility: hidden;
  transition: visibility 0s, opacity 0.3s linear;
  width: 44px;
  height: 18px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 5px 5px 5px 0px;
  background: #B56941;
  display: flex;
  right: -8px;
  top: -12px;
}

.transcript-tooltip__text {
  color: #FFF;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px
}

.mobile-toggle-transcript-button {
  display: none;

  @include media-breakpoint-down(lg){
    display: inline-flex;
  }
}

.intro .mobile-toggle-transcript-button {
  display: none;

  @include media-breakpoint-down(lg){
    display: none;
  }

  @include media-breakpoint-down(sm) {
    display: inline-flex;
  }
}

.toggle-transcript-button {
  display: inline-flex;

  @include media-breakpoint-down(lg){
    display: none;
  }
}

.toggle-transcript-button,
.mobile-toggle-transcript-button {
  position: relative;
  border-radius: 4px;
  background: #6998AE;
  height: 32px;
  &:before {
    content: "Read transcript";
  }

  box-shadow: 0 1px 10px 0 rgba(29, 32, 35, 0.15);
  padding: 12px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  &.active {
    background: #444050;

    &:before {
      content: "Close transcript";
    }
  }

  &.hidden {
    display: none;
  }
}
