.bottom-content-block {
  background-color: $porcelain;

  .panels-wrapper {
    @extend .container-xl-bounded;

    padding: 3rem 1.375rem 0.75rem; // 48px 22px 12px

    @include media-breakpoint-up(sm) {
      padding-left: 2.625rem; // 42px
      padding-right: 2.625rem; // 42px
    }

    @include media-breakpoint-up(xl) {
      padding: 3.375rem 6.625rem 4.5rem; // 54px 106px 72px
    }
  }

  .panel {
    margin-bottom: 2.25rem; // 36px

    @include media-breakpoint-up(lg) {
      padding-left: 1.5rem; // 24px
      padding-right: 1.5rem; // 24px;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 2.125rem; // 34px
      padding-right: 2.125rem; // 34px
    }
  }

  .heading {
    font-size: $h5-font-size;
    font-weight: $font-weight-bold;
    line-height: 1.32;
    margin-bottom: 1.5rem; // 24px
  }

  .panel-content {
    font-size: $font-size-xs;
    line-height: 1.4;
  }
}
