.bottom-quote {
  $angle-size: 3.1875rem; // 51px

  &.with-angle {
    margin-top: $angle-size;
  }

  .content-container {
    padding: 4.0625rem 2.5rem; // 65px 40px

    @include media-breakpoint-up(lg) {
      padding-top: 6rem; // 96px
      padding-bottom: 6rem; // 96px
    }
  }

  .quote-text {
    @extend .text-center;
    @extend .mx-auto;

    max-width: 750px;

    color: $black;
    font-size: 1.5rem; // 24px
    line-height: 1.08;

    @include media-breakpoint-up(lg) {
      font-size: $h3-font-size;
    }
  }

  .author {
    @extend .text-center;

    padding-top: 1rem; // 16px
    font-size: $font-size-sm;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-base;
    }
  }

  .decoration-line {
    $decoration-color: #e7cda5;

    background-image: linear-gradient($decoration-color, $decoration-color);
    background-repeat: no-repeat;
    background-size: 80px 3px;
  }

  .top-decoration {
    @extend .decoration-line;

    padding-top: 3rem; // 48px
    background-position: top;
  }

  .bottom-decoration {
    @extend .decoration-line;

    padding-bottom: 3rem; // 48px
    background-position: bottom;
  }

  .shape {
    @extend .shape-top;
  }

  .shape-img {
    padding-bottom: $angle-size;
  }
}
