.watch-episode .donate-block {
  @extend .position-relative;
  @extend .w-100;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .px-6;

  margin-bottom: 1.125rem;
  height: 10.4375rem;

  &.no-thumbnail {
    background-color: $graphite;
  }

  .content-container {
    @extend .text-center;
  }

  .support-us {
    @extend .font-weight-bold;

    margin-bottom: 1rem;
    font-size: 1.2rem;
    line-height: 1.32;
  }

  .btn-donate {
    @extend .btn;
    @extend .btn-primary;

    min-width: 13.75rem;
  }

  .study-tools-login {
    width: 20rem;
    margin-bottom: 1rem;
  }
}

.watch-episode .suggested-episodes-donate-block {
  @extend .d-md-none;
  @extend .w-100;
}

.watch-episode .next-episode-donate-block {
  @extend .d-none;
  @extend .d-md-block;
  @extend .d-xl-none;

  .donate-block {
    @extend .mb-1;

    // For the case when there's no thumbnail therefore there's no SVG blur
    border-radius: 0.5rem;

    .svg-blur {
      border-radius: 0.5rem;
    }
  }
}

.watch-episode
  .next-episode-container.no-suggested-episodes
  .next-episode-donate-block {
  @extend .d-block;
  @extend .d-xl-none;
  @extend .mt-4;
}

.study-tools-login-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 389px;
  text-align: center;
  width: 100%;

  .content-container {
    width: 100%;
  }

  .form-notes__item {
    color: #D1D3D4;
    font-size: 1rem;
    @extend .mb-0;
  }

  .study-tools-login-heading {
    @extend .d-inline-block;
    @extend .font-weight-normal;

    width: 100%;
    margin-bottom: 2.5rem;
    font-size: 2.25rem;
    line-height: $h1-line-height;
    color: $white;
  }

  .study-tools-login {
    width: 20rem;
    margin-bottom: 2.5rem;
  }

  .donate-cta-description {
    @extend .mb-5;

    font-size: 1rem;
    line-height: 1.375;
  }

  .btn-donate {
    @extend .btn;
    @extend .btn-primary;

    width: 15.5rem;
  }
}
