%satellite-info-block {
  @extend .mb-5;

  height: 13.875rem;

  .content-container {
    @extend .position-relative;
    @extend .h-100;
    @extend .d-flex;
    @extend .flex-column;

    padding: 2rem 2.5rem 2.5rem;
  }

  .spanner {
    flex-grow: 1;
  }

  .background-image {
    @extend .position-absolute;
    @extend .w-100;
    @extend .h-100;
    @include object-fit-cover;

    top: 0;
    left: 0;
    z-index: -2;
  }

  $overlay-color: #272530;
  .overlay {
    @extend .position-absolute;
    @extend .w-100;
    @extend .h-100;

    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(
      90deg,
      $overlay-color 12%,
      rgba($overlay-color, 0.75) 50%,
      rgba($overlay-color, 0.1)
    );
  }

  .title {
    font-size: 1.75rem;
  }

  .btn-more-info {
    @extend .btn;
    @extend .btn-primary;

    min-width: 11.875rem;
    padding-top: 0.5625rem;
    padding-bottom: 0.5625rem;
  }
}
