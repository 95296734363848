@import 'alchemy/elements/watch/shared/watch_section_title';

.watch-featured-section {
  @extend .mb-6;

  padding: 1.875rem 1.375rem;
  background-color: $baltic-sea;

  $prev-next-button-offset: 8.75rem;

  @include media-breakpoint-up(lg) {
    padding: map-get($spacers, 7) $prev-next-button-offset map-get($spacers, 8);
  }

  .section-title {
    @extend .mb-5;
    @extend .mb-lg-7;
    @extend .text-white;
    @extend .text-center;
    @extend .font-weight-normal;
    @extend %watch-section-title-font;
  }

  .slider-spacious .flickity-prev-next-button {
    @extend .d-none;
  }

  .flickity-prev-next-button {
    @extend .d-none;
    @extend .d-lg-inline-block;

    background-color: $secondary-bg;

    &:not(:disabled):not(.disabled):active {
      background-color: rgba($secondary-bg, 0.3);
    }
  }

  .flickity-prev-next-button.previous {
    left: -$prev-next-button-offset;
  }

  .flickity-prev-next-button.next {
    right: -$prev-next-button-offset;
  }
}
