.rich-text-container {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.rich-text-container.toolbox-top {
  flex-direction: column;

  .ql-snow .ql-tooltip {
    top: 1px !important;
    bottom: unset !important;
    z-index: 10;
  }
  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    top: 100%;
    bottom: unset;
  }
}

.ql-toolbar {
  width: 100%;
  z-index: 5;
}

.ql-toolbar.ql-snow,
.quill-editor.ql-snow {
  border: none;
}

.ql-undo,
.ql-redo {
  &:before {
    content: '\293A';
    font-size: 25px;
    line-height: 0;
    display: block;
  }
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  top: unset;
  bottom: 100%;
}
.ql-redo {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.ql-snow .ql-tooltip {
  top: unset !important;
  left: 50% !important;
  transform: translateX(-50%);
  margin: 0 !important;
  bottom: 1px !important;
}

.ql-formats {
  .ql-align .ql-picker-label {
    display: inline-block;
    float: left;
    width: 28px;
    svg {
      vertical-align: baseline;
    }
  }
}

.ql-snow .ql-editor {
  sup, sub {
    font-size: 11px;
  }
  .ad-quote {
    padding: 0 32px 20px;
    color: #323232;
    background: #f9f9f9;
    border-left: 3px solid #b56941;
    border-radius: 8px;
    margin: 0;
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;

    &:before {
      content: '“';
      color: #b56941;
      font-size: 64px;
      display: block;
      height: 50px;
      line-height: initial;
      font-family: 'DM Serif Display';
    }
  }
}

.ql-snow .ql-editor {
  .ad-definition[definition="null"], a {
    color: #6998ae;
  }
  a.ad-definition {
    color: #649546;
    text-decoration: none;
  }
}
.ql-snow {
  button.ql-ad-definition,
  button.ql-commentary,
  button.ql-expand,
  button.ql-extended-image {
    width: auto;
  }
  button.ql-extended-image {
    svg {
      width: 15px;
      margin-top: -5px;
    }
    .icon-extended-image {
      display: flex;
      flex-direction: column;
      font-size: 8px;
      align-items: center;
      line-height: 4px;
    }
  }
}
.ad-definition-tooltip {
  position: absolute;
  font-family: DM Sans;
  padding: 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #323232;
  background: white;
  box-shadow: 0 4px 30px 0 rgba(98, 98, 101, 0.4);
  max-width: 300px;
}
.ad-definition-tooltip__close-icon, .ad-definition-tooltip__title, .ad-definition-tooltip__link {
  display: none;
}
.ad-definition-tooltip__fade {
  opacity: 0;
  transition: opacity 0.5s linear;
}
.rs-tooltip-wrapper {
  position: relative;
  display: inline-block;
  margin-left: auto;
}
.rs-tooltip-container {
  z-index: 10;
  background-color:#fff;
  border: 1px solid#ccc;
  box-shadow: 0 0 5px#ddd;
  color:#444;
  padding: 5px 12px;
  white-space: nowrap;
  position: absolute;
  right: 5px;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .rs-tooltip-input {
    padding: 3px 5px;
    font-size: 13px;
    height: 26px;
    width: 200px;
  }
  textarea.rs-tooltip-input {
    min-width: 350px;
    max-width: 350px;
    max-height: 200px;
    min-height: 200px;
  }
}

.rs-tooltip-additional-fields {
  flex-basis: 100%;
  flex-grow: 1;
}
.rs-tooltip-additional-field {
  display: flex;
  align-items: center;
  div {
    width: 100px;
    text-align: left;
  }
  input {
    width: 100%;
  }
}

.rs-tooltip-label {
  margin-right: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.rs-tooltip__selection-text {
  font-weight: bold;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  max-width: 300px;
  height: unset;
  margin-bottom: 10px;
}
.rs-tooltip-action {
  margin-top: 10px;
  margin-left: auto;
}

.article-references {
  border-top: 1px solid #f3f4f5;
  border-bottom: 1px solid #f3f4f5;
  padding-bottom: 15px;
  width: 100%;
  overflow: auto;
  &:hover {
    background: #f3f4f5;
    cursor: pointer;
  }
  .expand-paragraph {
    padding: 0 10px;
  }
  .expand-title {
    border-bottom: 1px solid #eaedee;
    padding: 10px;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 500;
    font-family: DM Sans;
    &:before {
      content: '∟';
      color: #414042;
      font-size: 20px;
      margin-right: 8px;
      display: block;
      height: 30px;
      float: right;
      transform: rotate(135deg);
      font-weight: 800;
    }
  }
}
.article-references.collapsed {
  padding-bottom: 0;
  border-bottom: unset;

  * {
    display: none;
  }
  .expand-title {
    display: block;
    margin-bottom: 0;
    min-height: 45px;
    &:before {
      margin-top: -5px;
      margin-right: 10px;
      transform: rotate(315deg);
    }
  }
}

.ad-commentary {
  background: #f3f4f5;
  border-left: 3px solid #b56941;
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  overflow: auto;
  .commentary-title {
    color: #b56941;
    font-size: 14px;
    display: block;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.16em;
    text-align: left;
    min-height: 30px;
    text-transform: uppercase;
  }
}

.ad-extended-image {
  border: 1px solid black;
  display: inline-block;
  margin: auto 3px;
  img {
    margin: auto;
  }
}
.ad-extended-image.centered {
  display: block;
  margin: auto;
  width: fit-content;
}
#extended-image-overlay-wrapper {
  position: relative;
}
.extended-image-overlay {
  top: -623px;
  right: 61px;
  position: absolute;
  display: flex;
  &.hidden {
    display: none;
  }
  .selected svg {
    background: rgb(153, 153, 153) !important;
  }
}

// THEME
// LIGHT
$ql-toolbar-buttons-color-light: #000;
$ql-toolbar-buttons-hovered-color-light: #fff;
$ql-editor-text-color-light: #000;
$ql-placeholder-color-light: #757575;
$ql-editor-hover-background-light: #f9f9f9;
// DARK
$ql-toolbar-buttons-color: #fff;
$ql-toolbar-buttons-hovered-color: rgba(0, 0, 0, 0.85);
$ql-editor-text-color: #fff;
$ql-placeholder-color: #757575;
$ql-editor-hover-background: #32303c;

.light {
  .ql-editor {
    color: $ql-editor-text-color-light;
  }
  .ql-editor:hover {
    background: $ql-editor-hover-background-light;
  }
  .ql-formats {
    .ql-size,
    .ql-align {
      .ql-picker-label,
      .ql-picker-label.ql-active {
        background: none;
        color: $ql-editor-text-color-light;
        .ql-stroke {
          stroke: $ql-editor-text-color-light;
        }
      }
      .ql-picker-label:hover {
        background: $ql-toolbar-buttons-color-light;
        .ql-stroke {
          stroke: $ql-toolbar-buttons-hovered-color-light;
        }
      }
      .ql-picker-label:hover:before {
        color: $ql-toolbar-buttons-hovered-color-light;
        .ql-stroke {
          stroke: $ql-toolbar-buttons-hovered-color-light;
        }
      }
    }
    .ql-picker-label:before {
      color: $ql-toolbar-buttons-color-light;
    }
    .ql-picker-options {
      background: #444050;
      border: none;
    }
    .ql-picker-item {
      background: $ql-toolbar-buttons-hovered-color-light;
      color: $ql-toolbar-buttons-color-light;
      .ql-fill {
        fill: $ql-toolbar-buttons-color-light;
      }
      .ql-stroke {
        stroke: $ql-toolbar-buttons-color-light;
      }
      &.ql-selected,
      &:hover {
        background: $ql-toolbar-buttons-color-light;
        color: $ql-toolbar-buttons-hovered-color-light;
        .ql-fill {
          fill: $ql-toolbar-buttons-hovered-color-light;
        }
        .ql-stroke {
          stroke: $ql-toolbar-buttons-hovered-color-light;
        }
      }
    }
    button {
      color: $ql-toolbar-buttons-color-light;
      .ql-fill {
        fill: $ql-toolbar-buttons-color-light;
      }
      .ql-stroke {
        stroke: $ql-toolbar-buttons-color-light;
      }
    }
    button:hover {
      background: $ql-toolbar-buttons-color-light;
      color: $ql-toolbar-buttons-hovered-color-light;
      .ql-fill {
        fill: $ql-toolbar-buttons-hovered-color-light;
      }
      .ql-stroke {
        stroke: $ql-toolbar-buttons-hovered-color-light;
      }
    }
  }

  .ql-editor.ql-blank::before {
    color: $ql-placeholder-color-light;
  }
  .ql-undo,
  .ql-redo {
    color: $ql-toolbar-buttons-hovered-color-light;
    &:hover {
      background: $ql-toolbar-buttons-color-light;
      color: $ql-toolbar-buttons-hovered-color-light;
    }
  }
}

.dark {
  .ql-editor {
    color: $ql-editor-text-color;
  }
  .ql-editor:hover {
    background: $ql-editor-hover-background;
  }
  .ql-formats {
    .ql-size,
    .ql-align {
      .ql-picker-label,
      .ql-picker-label.ql-active {
        background: none;
        color: $ql-editor-text-color;
        .ql-stroke {
          stroke: $ql-editor-text-color;
        }
      }
      .ql-picker-label:hover {
        background: $ql-toolbar-buttons-color;
        .ql-stroke {
          stroke: $ql-toolbar-buttons-hovered-color;
        }
      }
      .ql-picker-label:hover:before {
        color: $ql-toolbar-buttons-hovered-color;
        .ql-stroke {
          stroke: $ql-toolbar-buttons-hovered-color;
        }
      }
    }
    .ql-picker-label:before {
      color: $ql-toolbar-buttons-color;
    }
    .ql-picker-options {
      background: #444050;
      border: none;
    }
    .ql-picker-item {
      background: #444050;
      color: $ql-toolbar-buttons-color;
      .ql-fill {
        fill: $ql-toolbar-buttons-color;
      }
      .ql-stroke {
        stroke: $ql-toolbar-buttons-color;
      }
      &.ql-selected,
      &:hover {
        background: #706c7a;
        color: $ql-toolbar-buttons-color;
        .ql-fill {
          fill: $ql-toolbar-buttons-color;
        }
        .ql-stroke {
          stroke: $ql-toolbar-buttons-color;
        }
      }
    }
    button {
      color: $ql-toolbar-buttons-color;
      .ql-fill {
        fill: $ql-toolbar-buttons-color;
      }
      .ql-stroke {
        stroke: $ql-toolbar-buttons-color;
      }
    }
    button:hover {
      color: $ql-toolbar-buttons-hovered-color;
      .ql-fill {
        fill: $ql-toolbar-buttons-hovered-color;
      }
      .ql-stroke {
        stroke: $ql-toolbar-buttons-hovered-color;
      }
    }
  }

  .ql-editor.ql-blank::before {
    color: $ql-placeholder-color;
  }
  .ql-undo,
  .ql-redo {
    color: $ql-toolbar-buttons-color;
    &:hover {
      color: $ql-toolbar-buttons-hovered-color;
    }
  }
}

.ql-editor.ql-blank::before {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.view-only {
  .ql-editor {
    padding: 0;
  }
  .ql-editor:hover {
    background: none;
  }
  .ql-toolbar {
    display: none;
  }
  img, video, iframe {
    cursor: unset !important;
  }
  .ad-definition {
    cursor: pointer;
  }
  .ad-extended-image {
    border: unset;
  }
}

@media (max-width: 991px) {
  .view-only {
    .ql-editor {
      img, video, iframe {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
  .ad-definition-tooltip {
    width: 100%;
    max-width: unset;
    left: 0 !important;
  }
  .ad-definition-tooltip__close-icon {
    display: block;
    position: absolute;
    color: #a6a6a6ff;
    font-size: 20px;
    right: 16px;
    top: 16px;
  }
  .ad-definition-tooltip__title {
    display: block;
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: #323232;
    margin-bottom: 5px;
  }
  .ad-definition-tooltip__link {
    display: inline-block;
    width: 100%;
    padding: 9px 0 9px 0;
    border-radius: 8px 0 8px 0;
    background: #6998ae;
    text-align: center;
    color: white !important;
    margin-top: 30px;
    font-family: DM Sans;
    font-weight: 500;
    font-size: 18px;
  }
}