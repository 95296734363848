@import 'application/navbar_mixins';

@mixin watch-page-common-styles {
  background-color: $watch-landing-background-color;

  // Prettier places each selector on separate line, that looks not pretty.
  // prettier-ignore
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $white;
  }

  .navbar {
    background-color: $watch-landing-navbar-color;

    .toggler-icon {
      stroke: $white;

      path {
        fill: $white;
      }
    }

    .logo {
      fill: $white;
    }

    // TODO: consider extracting all menu-related rules and other ones that are actually about
    // the modules and not the entire Watch-like page into their respective SCSS files
    // https://coda.io/d/Amazing-Discoveries-shared_daD69i5U8P9/Tech-debt-sink_suy3x#Tech-debt-tasks_tuJWy/r156

    // for dropdown sections' headings only
    .dropdown-header > :not(.icon) {
      @extend .text-white;
    }

    .mobile-dropdown-menu .dropdown-link,
    .desktop-dropdown-menu .dropdown-link {
      color: $watch-landing-mobile-dropdown-link-color;
    }

    .main-menu-wrapper .nav-link {
      color: $watch-landing-inactive-navbar-link-color;

      &.active {
        color: $white;
      }
    }

    .navbar-nav > .nav-item {
      border-color: $navbar-item-border-color-dark-bg;
    }
  }

  .footer {
    background-color: $secondary-bg;
  }
}

@mixin watch-text-base {
  color: $white;
  font-size: $font-size-xs;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-sm;
  }
}

@mixin transparent-background {
  background-color: rgba(0, 0, 0, 0);
}

@mixin watch-page-header-background {
  background: radial-gradient(
    circle,
    $watch-landing-background-color 60%,
    #2b292f 92%
  );
}

@mixin anchor-color-inherit {
  color: inherit !important;

  @include hover-focus-active {
    color: inherit !important;
  }
}

@mixin anchor-nested-color-inherit {
  a {
    @include anchor-color-inherit;
  }
}
