.watch-episode .suggested-next-card {
  @extend .d-flex;
  @extend .mw-xl-100;

  height: 5.625rem;

  .thumbnail-container {
    @extend .position-relative;

    flex: 0 0 9.875rem;
    background-color: $graphite;
  }

  .thumbnail {
    @include object-fit-cover;
    @extend .w-100;
    @extend .h-100;
  }

  .thumbnail-placeholder {
    @include centered-absolute;
    @extend .text-white;

    width: 2.5rem;
    height: 2.5rem;
  }

  .episode-details {
    @extend .pl-4;

    flex-grow: 1;
    font-size: $font-size-xs;
    overflow: hidden;
  }

  %text-overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .episode-title-container {
    @extend .mb-0;

    font-size: $font-size-xs;
    font-weight: $font-weight-bolder;
    line-height: 1.32;
  }

  .episode-title {
    @extend %text-overflow-ellipsis;
    @extend .d-block;
    @extend .text-white;
  }

  .episode-title-alt {
    @extend %text-overflow-ellipsis;
    @extend .d-block;

    color: $quick-silver;
  }

  .duration {
    @extend .text-white;
    @extend .position-absolute;

    right: 0.25rem;
    bottom: 0.25rem;

    padding-left: 0.375rem;
    padding-right: 0.375rem;

    font-size: $font-size-xxs;
    font-weight: $font-weight-bold;
    line-height: 1.375rem;
    background-color: rgba($watch-landing-navbar-color, 0.8);
  }

  %secondary-info {
    @extend %text-overflow-ellipsis;

    color: $nobel;
  }

  .speaker {
    @extend %secondary-info;
  }

  .speaker-link {
    color: inherit;
  }

  .footnote {
    @extend %secondary-info;
  }

  .series-link {
    color: inherit;
  }
  .special-episode-labels-container {
    display: flex;
    margin: 0;
    gap: 5px;
  }
  .special-episode-label {
    font-size: 12px;
    font-weight: 400;
    padding: 4px 10px;
    border-radius: 6px;
    color: #fff;

    &.premiering {
      background: #d83f76;
    }
    &.exclusive {
      background: #309967;
    }
    &.live_in {
      background: #5a3cd2;
    }
  }
}
