input[type="checkbox"].ios-switch {
  position: absolute;
  margin: 0.5rem 0 0 1rem;
  display: none;
}
input[type="checkbox"].ios-switch + label {
  position: relative;
  padding: 0.313rem 0 0 3.13rem;
  line-height: 2.0em;
}
input[type="checkbox"].ios-switch + label:before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 2.5rem; /* x*5 */
  height: 1.5rem; /* x*3 */
  border-radius: 1rem; /* x*2 */
  background: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
input[type="checkbox"].ios-switch + label:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 1.5rem; /* x*3 */
  height: 1.5rem; /* x*3 */
  border-radius: 1rem; /* x*2 */
  background: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-shadow: 0 0 0.313rem rgba(0,0,0,0.3);
}
input[type="checkbox"].ios-switch + label:hover:after {
  box-shadow: 0 0 0.313rem rgba(0,0,0,0.3);
  cursor: pointer;
}
input[type="checkbox"].ios-switch:checked + label:after {
  margin-left: 1rem;
}
input[type="checkbox"].ios-switch:checked + label:before {
  background: $primary;
}

input[type="checkbox"].ios-switch:not(:checked) + label:before {
  border: 1px solid #fff;
}
