@import 'application/navbar_mixins';

.main-menu-wrapper {
  @extend .navbar-nav;
  @extend .justify-content-center;
  @extend .w-100;

  &:first-child {
    @extend .mt-2; // overriding the non-necessary rule from the theme
  }

  .nav-item {
    border-top: none;
  }

  .nav-item:hover {
    @media (max-width: 991.98px) {
      background-color: #F3F4F5;
    }
  }

  .nav-link {
    font-size: 16px;
    color: #ffffff;

    @media (max-width: 991.98px) {
      color: #323232 !important;
    }
  }

  // the last menu item on the Home page
  .buy-nav-item {
    // this menu item comes right after Watch sub-menu on mobile, to push itself from it
    @extend .mt-2;
    @extend .mt-lg-0;

    // margin to align this items with regular main_menu nodes on mobile
    @extend .mx-4;
    @extend .mx-lg-0;

    // to pull the Donate link (which is always present) vertically closer to the "Buy" node link
    @extend .mb-n5;

    .nav-link {
      @extend .ml-n4; // negative margin for link to compensate margin of the parent block
      @extend .ml-lg-0;
    }
  }

  li.donate-link-nav-item {
    display: flex;
    max-width: 400px;
    flex-wrap: wrap;
    flex-basis: max-content;
    margin: 0 auto;
    width: 100%;
    .btn-donate {
      display: block !important;
      margin: 0 16px;
      width: 100%;
    }
    .nav-donation-button-dropdown {
      margin-top: 0;
    }
  }
}

//Watch menu wrapper
.main-menu-wrapper-watch {
  @extend .navbar-nav;
  @extend .justify-content-center;
  @extend .w-100;

  &:first-child {
    @extend .mt-2; // overriding the non-necessary rule from the theme
  }

  .nav-item {
    border-top: none;
  }

  .nav-item:hover {
    background-color: #1C1923;
  }

  .nav-link {
    font-size: 16px;
    color: #ffffff !important;
  }

  // the last menu item on the Home page
  .buy-nav-item {
    // this menu item comes right after Watch sub-menu on mobile, to push itself from it
    @extend .mt-2;
    @extend .mt-lg-0;

    // margin to align this items with regular main_menu nodes on mobile
    @extend .mx-4;
    @extend .mx-lg-0;

    // to pull the Donate link (which is always present) vertically closer to the "Buy" node link
    @extend .mb-n5;

    .nav-link {
      @extend .ml-n4; // negative margin for link to compensate margin of the parent block
      @extend .ml-lg-0;
    }
  }

  li.donate-link-nav-item {
    display: flex;
    max-width: 400px;
    flex-wrap: wrap;
    flex-basis: max-content;
    margin: 0 auto;
    width: 100%;

    .btn-donate {
      display: block !important;
      margin: 0 16px;
      width: 100%;
    }

    .nav-donation-button-dropdown {
      margin-top: 0;
      background-color: var(--grayscale-strong-grey, #32303C);
      @media (max-width: 1200px) {
        background: none;
      }
      a {
        color: var(--backgrounds-white, #FFF);
      }
    }
  }
}

.nav-donation-dropdown {
  position: relative;

  .nav-donation-button {
    font-size: 16px !important;
  }
}
.nav-donation-button-dropdown {
  min-width: 160px;
  padding: 16px 40px;
  left: 0;
  border-radius: 0 0 8px 8px;
  margin-top: 16px;
  a {
    display: block;
    color: var(--text-primary, #323232);
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    padding: 8px 0;
  }
}

.donate-link-nav-item {
  @media (max-width: 1200px) {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  @media (min-width: 992px) {
    display: none !important;
  }
}

.watch-landing li.donate-link-nav-item {
  @media (min-width: 1200px) {
    display: none;
  }
}
.nav-donation-button-dropdown-hidden .nav-donation-button-dropdown {
  display: none;
}
