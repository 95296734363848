// Colors

$manatee: #8f8a9b;
$baltic-sea: #1c1a23;
$quick-silver: #a6a6a6;
$onyx: #393542;
$graphite: #1d1b21;
$nobel: #b4b4b4;
$scarpa-flow: #514c5a;
$amethyst-smoke: #9d97ab;

$watch-landing-background-color: #131118;
$watch-landing-navbar-color: #0b090f;
$watch-landing-inactive-navbar-link-color: #969696;
$watch-landing-mobile-dropdown-link-color: $raw-sienna;
$secondary-bg: #2a2732;
$title-alt-color: $quick-silver;
$title-alt-color-lighter: #bbb;

$search-form-color: $white;
$watch-landing-dropdown-menu-color: $graphite;
$watch-landing-sub-menu-bg-color: $graphite;

// Sizes

$entry-card-thumbnail-width: 246px;
$entry-card-thumbnail-height: 140px;
