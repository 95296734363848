.static-page {
  .hero {
    .content-container {
      @extend .pt-12;
      @extend .pb-9;
      @extend .pt-lg-15;
      @extend .pb-lg-13;
    }
  }

  .contents-wrapper {
    @extend .pt-9;
    @extend .pb-7;
    @extend .py-lg-11;
    @extend .pt-xl-13; // keeping .pb-xl-11

    max-width: 1200px;
  }

  .richtext-block {
    ul {
      @extend .list-checked-secondary;
    }
  }
}
