@import 'application/navbar_mixins';
@import 'sites/watch/variables';
@import 'sites/watch/shared/search_input';

// Desktop styles

.watch-landing-sub-menu {
  background-color: $watch-landing-sub-menu-bg-color;

  .dropdown-button {
    // clear the non-necessary defaults from the theme
    @extend .mr-0;
    border-radius: 0 !important;
  }

  .dropdown-menu {
    @extend .position-absolute;
    @extend .flex-column;
    @extend .flex-wrap;
    @extend .align-content-between;
    @extend .justify-content-between;
    @extend .px-8;
    @extend .py-7;

    top: 64px;
    left: 32px;

    &.show {
      display: grid;

      grid-template-columns: repeat(2, 1fr);

      width: max-content !important;
      height: auto !important;
      left: -15% !important;
      border-radius: 0 0 1rem 1rem;
      top: 2.8rem;
    }

    min-width: auto; // IE11 doesn't support initial, place this first
    min-width: initial; // clearing default BS value for dropdown
    background-color: $secondary-bg;
  }

  // node-specific dropdown styles

  .dropdown-toggle-icon {
    display: inline;
    vertical-align: middle;
  }

  .dropdown-menu-item {
    @extend .d-inline;
    @extend .mr-8;
    @extend .mb-4;

    %thumbnail-like {
      width: 1.65rem;
      height: 1.65rem;
      margin-right: 0.75rem; // 12 px
      border-radius: 50%;
      vertical-align: middle;
    }

    max-width: 300px; // to wrap long Topics, Categories, etc.

    .thumbnail {
      @include object-fit-cover;
      @extend %thumbnail-like;
      border: 0.5px solid #D1D3D4;
    }

    .placeholder {
      @extend %thumbnail-like;
      @extend .d-inline-block;

      background-color: lightgray;
    }
  }

  // item containing the "View All" link
  .dropdown-menu-item:last-child {
    @extend .mt-auto;
    grid-column: -3;
    font-size: 1rem !important;
  }

  .dropdown-menu-item-link {
    @extend .text-white;

    @include hover-focus-active {
      color: $manatee !important;
    }

    &.active {
      font-weight: $font-weight-bolder;
    }
  }

  .dropdown-menu-view-all-link {
    @extend .font-weight-bold;
    svg.feather-chevron-right {
      margin-top: -3px;
      height: 20px;
    }
  }
}

.site-watch .watch-landing-sub-menu.flat {
  @extend .d-none;
  @extend .d-xl-flex;
  // only 2 flex items (nav and search form), this will them to
  // the left-right edges of the sub-menu container
  @extend .justify-content-xl-between;
  // Won't work for IE11. Also, there's no working and publicly available polyfill for IE11.
  // We decide to degrade on this functionality in IE11.
  @extend .position-sticky;
  top: 0;
  z-index: 1000;

  .nav-item {
    @include media-breakpoint-up(xl) {
      margin-right: 2.25rem; // 36px
    }
  }

  .nav-item.active {
    background-image: linear-gradient($primary, $primary);
    background-repeat: repeat-x;
    background-size: 0.125rem 0.125rem;
    background-position: 0 98%;

    .nav-link {
      @extend .text-white;
    }
  }

  .search-form {
    @extend .position-relative;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .mr-7;
    @extend .font-size-sm;

    .search-input {
      @extend %search-input;
      @extend %search-input-border;
      @extend .pl-5;
      @extend .pr-2;
      @extend .bg-transparent;
      @extend .border-transparent;
      @extend .rounded;
      @include search-input-clear-button;
      @include search-input-autofill;

      color: $search-form-color;

      &:-webkit-autofill:first-line {
        @extend .font-size-sm;
      }

      &::placeholder {
        @extend %search-placeholder-element-color;
      }
    }

    .search-toggler-icon {
      @extend .position-absolute;
      @extend .ml-2;
      @extend %search-placeholder-element-color;

      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1rem;
      height: 1rem;
    }
  }
}

// Mobile styles

.watch-landing-sub-menu {
  // Index pages menu items styles

  .index-page-nav-link {
    @extend .d-xl-none;
  }

  // Dropdown menu styles

  .dropdown {
    @extend .d-none;
    @extend .d-xl-block;
    @extend .position-relative;
  }
}

.body.site-watch .watch-landing-sub-menu.embedded {
  @extend %main-menu-watch-sub-menu-mobile;
  @extend .d-xl-none;

  .nav-item:nth-child(n + 2) {
    @include navbar-item-border-top-dark-bg;
  }
}
