.flickity-resize .watch-series-banner-slide {
  min-height: 100%;
}

.watch-series-banner-slide {
  @extend .flex-column;
  @extend .flex-lg-row;
  @extend .w-100;

  color: $white;

  .cover-col {
    @extend .position-relative;

    height: 230px;

    @include media-breakpoint-up(md) {
      height: 300px;
    }

    @include media-breakpoint-up(lg) {
      height: auto; // IE11
      height: initial;
    }
  }

  .cover {
    @include cover;
  }

  .content-wrapper {
    padding: 1.125rem;
    padding-bottom: 0.25rem;
    flex-grow: 1;

    @include media-breakpoint-up(md) {
      padding: 2.75rem;
      padding-bottom: 0.25rem;
    }

    @include media-breakpoint-up(lg) {
      // becomes flex row

      flex-grow: 0;
      height: auto;
      padding-top: 1.76rem;
      padding-right: 5rem;
    }
  }

  .title-container {
    line-height: 1.08;

    @include media-breakpoint-up(md) {
      line-height: 1.23;
    }
  }

  .title {
    @include anchor-nested-color-inherit;
    @extend .mb-0;

    font-size: $h4-font-size;

    a {
      overflow: hidden;
      max-width: 35rem;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    @include media-breakpoint-up(md) {
      font-size: 2.125rem;
    }
  }

  .title-alt {
    margin-top: 0.5rem;

    a {
      overflow: hidden;
      max-width: 35rem;
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
      color: $white;

      @include hover-focus-active {
        color: $white;
      }
    }
  }

  .title-container .title-alt {
    font-size: $font-size-base;
  }

  .series-details {
    font-size: $font-size-xs;
    margin-top: 0.5rem;

    @include media-breakpoint-up(md) {
      font-size: $font-size-sm;
    }
  }

  .series-count {
    margin-top: 1rem;
    color: #D1D3D4;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .topics {
    @extend .d-none;
    @extend .d-md-block;
    @extend .mt-3;

    height: 1.7rem;
    overflow: hidden;
  }

  .featured-episode {
    @extend .mt-2;
  }

  %featured-episode-title-font {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
  }

  .featured-episode-heading {
    @extend .mb-0;
    @extend %featured-episode-title-font;
  }

  .featured-episode-title-alt {
    margin-top: 0.5rem;
    @extend %featured-episode-title-font;
    color: $title-alt-color-lighter;
  }

  .duration {
    @extend .font-size-sm;

    font-weight: $font-weight-normal;
    color: $title-alt-color-lighter;
  }

  .featured-episode-description {
    @extend .d-none;
    @extend .d-md-block;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    overflow: hidden;
    max-width: 35rem;
    display: -webkit-box !important;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  .action-buttons {
    @extend .mt-2;
  }

  .action-button {
    font-size: $font-size-sm;

    @include media-breakpoint-up(md) {
      font-size: $font-size-base;
    }

    margin-bottom: 0.875rem;
    padding: 0.5625rem 0.75rem;
    width: 10rem;
    color: inherit;
  }

  .action-buttons .btn-primary {
    margin-right: 0.875rem;
  }

  .action-buttons .btn-secondary {
    background-color: $onyx;
  }
}
