.watch-featured-banner-slide__col {
  @extend .col-lg-6;
  padding-right: 8px;
}

.watch-featured-banner-slide {
  @extend .row;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;

  .content-wrapper {
    padding-left: 0;

    @include media-breakpoint-down(lg) {
      padding-left: 18px;
    }
  }
}

.watch-featured-banner-slide__image-container {
  height: 100%;
  width: 100%;
  justify-content: end;
  display: flex;
  padding-top: 32px;

  img {
    height: 400px;
    max-width: 100%;
  }

  @include media-breakpoint-down(lg) {
    justify-content: center;
    align-items: baseline;
    padding-top: 32px;
    padding-left: 70px;
    height: 340px;

    img {
      height: 340px;
    }
  }

  @include media-breakpoint-down(md) {
    height: 300px;
    padding-left: 0;

    img {
      height: 300px;
    }
  }

  @include media-breakpoint-down(sm) {
    justify-content: center;
    align-items: baseline;
    padding-top: 20px;
    padding-left: 0;
    height: 230px;

    img {
      height: 230px;
    }
  }
}

.watch-featured-banner-slide__text-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 56px;

  @include media-breakpoint-down(lg) {
    padding-left: 40px;
    padding-top: 16px;
  }

  @include media-breakpoint-down(md) {
    padding-top: 0;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 16px;
  }
}

.watch-featured-banner-slide__title,
.watch-featured-banner-slide__sub-title,
.watch-featured-banner-slide__description {
  color: $white;
  font-family: 'DM Sans';
}

.watch-featured-banner-slide__title {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;

  @include media-breakpoint-down(lg) {
    line-height: 1.23;
    font-size: 34px;
  }

  @include media-breakpoint-down(sm) {
    line-height: 1.08;
    font-size: 28px;
  }
}

.watch-featured-banner-slide__sub-title {
  max-width: 35rem;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-top: 24px;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @include media-breakpoint-down(lg) {
    line-height: 1;
    font-size: 20px;
  }

  @include media-breakpoint-down(sm) {
    line-height: 1.08;
    font-size: 18px;
  }
}

.watch-featured-banner-slide__description {
  max-width: 31.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin-top: 18px;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  padding-right: 16px;

  @include media-breakpoint-down(lg) {
    -webkit-line-clamp: 20;
    line-height: 1.08;
    font-size: 16px;
  }

  @include media-breakpoint-down(sm) {
    line-height: 1.08;
    font-size: 14px;
  }

  strong {
    font-weight: 500;
  }
}
