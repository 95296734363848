.player-next-episode__container {
  position: relative;
  display: none;
  .suggested-next-card {
    border-bottom: none;
    margin: 20px 0;
    .next-episode-col {
      padding: 0;
    }
  }
  .suggested-content-title {
    display: none;
  }
  .player-next-episode__background {
    width: 100%;
    height: 532px;
    position: absolute;
    background: #1c1923;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .player-next-episode__main {
    width: 100%;
    max-width: 350px;
  }

  .player-next-episode__next-in {
    font-size: 16px;
    font-weight: 400;
  }
  .player-next-episode__counter {
    font-weight: 500;
  }

  .controls {
    display: flex;
    justify-content: space-between;
  }

  .button {
    display: inline-block;
    font-weight: 500;
    color: #5c5c5c;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 7px 45px;
    font-size: 18px;
    line-height: 26px;
    border-radius: 0.5rem 0 0.5rem 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #ffffff;
    box-shadow: 0 0 6px rgba(50, 50, 50, 0.03), 0 14px 24px rgba(50, 50, 50, 0.06);
    cursor: pointer;
    &.cancel {
      background-color: #444050;
      border-color: #444050;
    }
    &.play_now {
      background-color: #6998ae;
      border-color: #6998ae;
    }
  }
}
