.svg-blur {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

.svg-blur-overlay {
  @extend .position-absolute;
  @extend .w-100;
  @extend .h-100;

  top: 0;
  left: 0;
  background-color: rgba($black-original, 0.45);
  z-index: -1;
}

.svg-blur-overlay-light {
  @extend .position-absolute;
  @extend .w-100;
  @extend .h-100;
  background-color: rgba($black-original, 0.30);

  top: 0;
  left: 0;
  z-index: -1;
}
