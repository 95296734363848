.new-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.25rem 0.75rem;
  position: absolute;
  width: 49px;
  height: 1.5rem;
  left: 0;
  top: 12px;
  background: #D18F42;
  border-radius: 0 4px 4px 0;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
}

.news-new-label {
  z-index: 10;
  top: 0;
  border-radius: 0 0 4px 0;
}
