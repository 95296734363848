.watch-landing .watch-series-section .section-title {
  margin-bottom: 0.3rem;
}
.series-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.25rem 0.75rem;
  position: absolute;
  width: auto;
  height: 1.5rem;
  left: 0;
  top: 0;
  background: #B56941;
  border-radius: 0 0 0.25rem 0;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  color: #FFFFFF;
}

.episode-label {
  position: absolute;
  padding-top: 6px;
  width: 100%;
  height: 30px;
  left: 0;
  top: 110px;
  background: rgba(50, 145, 147, 0.5);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: center;
}
.episode-progress {
  position: relative;
}
.episode-progress__back {
  width: 100%;
  height: 4px;
  background: rgba(209, 211, 212, 0.80);
  position: absolute;
  top: -4px;
}
.episode-progress__back__with-offset {
  top: -8px;
}
.episode-progress__percent {
  height: 4px;
  background: #D18F42;
  max-width: 100%;
}
