.speaker-entry {
  @extend .p-4;
  @extend .rounded-top-left;
  @extend .rounded-bottom-right;
  @extend .text-xl-center;

  $thumbnail-size: 5rem; // 80px
  $thumbnail-size-xl: 6.875rem; // 110px

  min-height: $thumbnail-size + 2rem; // top and bottom paddings from .p-4
  background-color: #2a2732;

  // .entry-content should have "position: static" for the "whole-card" link to cover
  // the entire Speaker card in the +watch_all_speakers_section+ element.
  // .entry-content covers only a part of it, while its parent, .watch-entry, is the entire card
  // and has "position: absolute" thanks to the surrounding Flickity slider
  .entry-content {
    @extend .position-static;
  }

  .thumbnail-wrapper {
    @extend .position-relative;
    @extend .mx-xl-auto;
    @extend .mb-2;
    @extend .mb-xl-3;

    width: $thumbnail-size;
    height: $thumbnail-size;
    margin-right: 0.875rem; // 14px
    float: left;

    @include media-breakpoint-up(xl) {
      float: none;

      width: $thumbnail-size-xl;
      height: $thumbnail-size-xl;
    }
  }

  .thumbnail {
    @include object-fit-cover;
    @extend .rounded-top-left;
    @extend .w-100;
    @extend .h-100;
  }

  .image-icon {
    @extend .position-absolute;
    @extend .text-white;

    width: 50%;
    height: 50%;
    left: 25%;
    top: 25%;
  }

  .title {
    @extend .font-weight-bold;
    // Added to make topics flow around thumbnail beautifully
    // on breakpoints less than xl
    @extend .mb-2;
    @extend .mb-xl-0;

    // don't use @extend .font-size-sm because it's not compatible with IE11
    font-size: $font-size-sm !important;
    line-height: $line-height-base;
  }

  .series-count-info {
    @extend .mb-3;
    @extend .text-white;
    @extend .font-size-sm;

    line-height: $line-height-base;
  }

  .topics {
    @extend .mt-xl-3;
    @extend .text-xl-left;
  }

  .topic-link {
    @extend .position-relative; // to make it clickable through the internal "whole-card" link
  }
}
