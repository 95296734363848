.watch-episode {
  @include max-width-xxl;

  color: $white;
  padding-bottom: 1.313rem;

  @include media-breakpoint-up(md) {
    padding: 0 1.688rem 1.313rem 1.688rem;
  }

  .main-content {
    @extend .d-xl-flex;
  }

  .player-wrapper {
    flex-grow: 1;

    @include media-breakpoint-up(xl) {
      padding-right: 1.688rem;
      max-width: 70%;
    }
  }

  .player {
    @extend .position-relative;
  }

  .video-preview-container {
    position: absolute;
    bottom: 60px;
    margin-left: -85px;
    width: 175px;
    height: 98px;
    background-image: url(#{$path-to-img}/loading.gif);
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #d9d9d9;
    background-color: rgba(217, 217, 217, 0.51);
    #video-preview {
      border: 1px solid #d9d9d9;
      margin-left: -1px;
      margin-top: -1px;
    }
  }

  .episode__unpublished {
    height: 528px;
    background-color: #2B2834;
    position: relative;
  }

  .episode__unpublished-text {
    position: absolute;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
    top: 45%;
    left: 35%;

    @media (max-width: 451px) {
      left: 10%;
    }
  }

  .episode__unpublished-icon {
    margin-top: -4px;
    margin-right: 8px;
  }

  .episodes-selector-app {
    display: flex;
  }

  .episode-transcript-app {
    display: flex;
    flex-direction: column;
  }

  .episode-transcript {
    &.hidden {
      display: none;
    }

    display: block;
  }

  .vjs-poster {
    background-size: cover;
  }

  .video-placeholder {
    @extend .d-flex;
    @extend .flex-column;
    @extend .text-center;

    height: 250px;
    padding: 1.25rem 1.25rem 0;

    @include media-breakpoint-up(xl) {
      max-width: 600px;
    }

    .icon-container {
      @extend .position-relative;

      flex-grow: 1;
    }

    .icon {
      @include centered-absolute;

      width: 50px;
      height: 50px;
    }
  }

  .content-wrapper {
    @extend .px-md-0;

    padding: 1.25rem;

    @include media-breakpoint-up(md) {
      padding-top: 1.688rem;
    }
  }

  .heading-container {
    @extend .d-flex;
    @extend .mb-3;
    @extend .mb-md-4;
  }

  .intro {
    @extend .pr-4;

    flex-grow: 1;
  }

  .title-container {
    line-height: 1.08;
  }

  .views-count {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-align: center;
    color: #8B8B8B;
    margin-right: auto;
  }

  .title {
    @extend .mb-1;

    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
  }

  .title-alt {
    @extend .mb-1;

    font-size: $font-size-sm;
    color: $title-alt-color;
  }

  .episode-details {
    font-size: $font-size-xxs;

    @include media-breakpoint-up(md) {
      font-size: $font-size-base;
    }
  }

  .series-link {
    @extend .text-nowrap;
    @extend .font-weight-bold;

    font-size: $font-size-sm;
    color: inherit;
  }

  .speaker-link {
    @extend .text-nowrap;

    color: $nobel;
  }

  .episode-sequence-number {
    @extend .text-nowrap;

    color: $nobel;
  }

  .collapsible-text-container {
    margin-bottom: 0.625rem;
  }

  .collapsible-text-toggle {
    @extend .mx-auto;
    @extend .ml-md-0;
  }

  .speaker {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }

  .speaker img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1.5rem;

    position: static;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .speaker span {
    padding-left: 0.25rem;
    position: static;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #FFFFFF;
  }

  .description {
    font-size: $font-size-xs;
    line-height: 1.125rem;
    max-width: 34.875rem;
  }

  .topics {
    margin-top: 0.625rem;
  }

  .suggested-content {
    display: block;

    @include media-breakpoint-down(lg){
      display: none;
    }

    @include media-breakpoint-up(xl) {
      max-width: 30%;
    }
  }

  .suggested-content-mobile {
    display: none;

    @include media-breakpoint-down(lg){
      display: block;

      .mobile-episode-transcript-app {
        display: flex;
        flex-direction: column;
        padding: 15px 0;
      }
    }

    @include media-breakpoint-down(sm) {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }

  %suggested-content-container {
    @extend .pt-3;
    @extend .pb-4;
  }

  .next-episode-container {
    @extend %suggested-content-container;

    border-bottom: 1px solid #303030;

    &.hidden {
      display: none;
    }
  }

  %no-suggested-content {
    @extend .py-0;

    border-bottom: none;
  }

  .next-episode-container.no-suggested-episodes {
    @extend %no-suggested-content;
  }

  .next-episode-container.no-next-episode {
    @extend %no-suggested-content;
  }

  .next-episode-col {
    @extend .pt-md-4;
    @extend .pt-xl-0;
  }

  .suggested-content-title {
    @extend .mb-4;

    font-size: 1.3125rem;
    font-weight: $font-weight-bold;
  }

  .suggested-episodes-container {
    @extend %suggested-content-container;
  }
}

.embedded {
  overflow: hidden;

  .video-js {
    height: 100vh;
    width: 100%;
  }
}

#likes-app {
  display: flex;
  margin-left: auto;
}

.episode-actions-block {
  margin-top: -16px;
  margin-bottom: 16px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.all-store-items-app {
  display: block;
}
