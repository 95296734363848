//
// forms.scss
//

// Form control

.form-control {
  background-clip: border-box;
  background-color: $white;
  border-radius: 0.5rem;

  &:disabled, &:disabled:focus {
    background-color: $input-disabled-bg-color;
    border: $border-placeholder-shown;
  }

  &:read-only, &:read-only:focus {
    border: $border-placeholder-shown;
  }

  &:focus {
    background-color: $white;
  }

  &.invalid-input {
    border: $border-invalid-input;
  }

  &.is-invalid {
    border: $border-invalid-input;
  }

  &:not(:placeholder-shown):not(:disabled) {
    border: $border-not-placeholder-shown;
  }

  &:placeholder-shown {
    border: $border-placeholder-shown;
  }
}

.select2-container {
  background-color: $white;
}

// Underline

.form-control-underline {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border-radius: 0;
  border-width: 0;
  border-bottom-width: $input-border-width;
  border-bottom-color: $border-color;

  &:focus {
    background-color: transparent;
    border-bottom-color: $primary;
  }
}


// Feedback

.form-control.is-valid,
.form-control.is-invalid {
  background-size: $form-feedback-bg-size;
}

.custom-select.is-valid,
.custom-select.is-invalid {
  background-size: $custom-select-bg-size, $form-feedback-bg-size;
}
