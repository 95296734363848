.custom-tabs {
  .tab-list {
    display: flex;
    border-bottom: 1px solid #EAEDEE;
    margin-bottom: 20px;
    .tab-item {
     padding: 5px 10px;
      cursor: pointer;
      font-size: 18px;

      &.active {
        color: #6998ae;
        border-bottom: 2px solid #6998ae;
      }
    }
  }
  .tab-content {
    display: none;
    &.active {
      display: block;
    }
  }
}