.watch-heading {
  .heading {
    @include anchor-nested-color-inherit;
    @extend .mb-0;
    @extend .px-5;
    @extend .py-2;
    @extend .px-md-7;
    @extend .py-md-3;
    @extend .px-xl-8;
    @extend .py-xl-4;

    font-size: $font-size-base;
    line-height: $h1-line-height; // 1.32

    @include media-breakpoint-up(sm) {
      font-size: $h4-font-size;
    }
  }
}
