.watch-topic {
  .watch-grid-container {
    padding-top: 1.6875rem;

    @include media-breakpoint-up(xl) {
      padding-top: 3.5rem;
    }
  }

  .watch-entry .title {
    @extend .font-weight-bold;

    font-size: $font-size-xs;
    line-height: 1.28;
  }
}
