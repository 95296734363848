//
// dropdown.scss
//

// Menu

.dropdown-menu {
  padding: $dropdown-padding-y $dropdown-padding-x;
}


// Sizing

.dropdown-menu-auto {
  min-width: auto;
}

.dropdown-menu-lg {
  min-width: $dropdown-min-width-lg;
}

.dropdown-menu-xl {
  min-width: $dropdown-min-width-xl;
}


// Flush

.dropdown-menu-flush {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}


// Grid

.dropdown-menu-col {
  background-color: $dropdown-bg;
  border-radius: $dropdown-border-radius;
  box-shadow: $dropdown-box-shadow;
}

.dropdown-menu-body {
  padding: $dropdown-padding-y $dropdown-padding-x;
}

.dropdown-menu-footer {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  margin-left: $dropdown-padding-x;
  margin-right: $dropdown-padding-x;
  border-top: $border-width solid $border-color;
}


// Item

.dropdown-item + .dropdown-item {
  margin-top: .75rem;
}


// Link

.dropdown-link {
  @extend .dropdown-item;
}


// Header

.dropdown-header {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 1.25rem;
  font-size: $h6-font-size;
}

.dropdown-item + .dropdown-header {
  margin-top: 2.5rem;
}
