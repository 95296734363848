%page-banner-content-wrapper {
  min-height: 17.5rem;
  padding: 1.625rem 1.125rem 1.375rem;

  @include media-breakpoint-up(sm) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include media-breakpoint-up(md) {
    padding: 2.25rem;
  }

  @include media-breakpoint-up(xl) {
    padding: 2.75rem;
  }
}
