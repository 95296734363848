//
// list-group.scss
//

// Flush

.list-group-flush .list-group-item {
  padding-left: 0;
  padding-right: 0;
  border-width: 0;
}

.list-group-flush .list-group-item + .list-group-item {
  border-top-width: $border-width;
}

.list-group-flush .list-group-item.active {
  background-color: $list-group-bg;
  color: $list-group-color;
}

.list-group-flush .list-group-item + .list-group-item.active {
  margin-top: 0;
}


// Sizing

.list-group-lg .list-group-item {
  padding-top: $list-group-item-padding-y-lg;
  padding-bottom: $list-group-item-padding-y-lg;
}

.list-group-sm .list-group-item {
  padding-top: $list-group-item-padding-y-sm;
  padding-bottom: $list-group-item-padding-y-sm;
}


// Hover

.list-group-hover .list-group-item {
  position: relative;
  transition: $transition-base;
  transition-property: border-color;
}

.list-group-hover .list-group-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: calc(100% + 5rem);
  border-radius: $border-radius 0 $border-radius 0;
  box-shadow: $list-group-hover-box-shadow;
  transition: $transition-base;
  transform: translateX(-50%);
  opacity: 0;
}

.list-group-hover .list-group-item:hover,
.list-group-hover .list-group-item:hover + .list-group-item[href] {
  border-top-color: transparent;
}

.list-group-hover .list-group-item:hover::before {
  opacity: 1;
}
