.hero-redesigned-short {
  @extend .position-relative;
  @extend .overflow-hidden;

  margin-bottom: -4.5rem !important;
  font-family: 'DM Sans', sans-serif;

  .content-container {
    padding: 16px;
  }

  .content-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 320px;

    @include media-breakpoint-down(md) {
      height: 400px;
      align-items: start;
      justify-content: flex-end;
    }
  }

  .heading {
    color: $white;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .sub-title {
    color: $white;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;

    @include media-breakpoint-down(md) {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    max-width: 480px;

    color: $white;
    margin-bottom: 48px;
    p {
      &:nth-child(odd) {
        font-size: 18px;
      }
      margin-bottom: 10px;
    }
    @include media-breakpoint-down(md) {
      font-size: $font-size-sm;
      line-height: $line-height-sm;
      margin-bottom: 24px;
    }
  }

  h1.heading {
    margin-bottom: 10px;
  }

  .breadcrumbs-main {
    .breadcrumb a.active {
      color: $white;
    }
  }

  .btn-watch {
    @extend .btn-primary-default;
  }

  .scroll-down-container {
    @extend .position-relative;
  }

  .btn-scroll-down {
    @extend .position-absolute;
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-circle;

    left: 50%;
    bottom: .5rem;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .background {
    @include object-fit-cover;
    @extend .position-absolute;

    // Not using @extend and utility Bootstrap classes
    // because !important that is brings prevents
    // objectFitVideos from working correctly for IE
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    z-index: -1;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 0 !important;
  }

  @include media-breakpoint-between(lg, xl) {
    margin-bottom: -1.5rem !important;
  }
}
