//
// navbar.scss
//

.navbar {
  transition: $transition-base;
  transition-property: box-shadow;
}

.navbar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @include border-multicolor();
}


// Brand

.navbar-brand {
  font-family: $font-family-serif;
}


// Toggler

.navbar-toggler {
  padding-left: 0;
  padding-right: 0;
}

.search-btn-mobile {
  margin: 23px -16px 0 auto;

  @media (min-width: 992px) {
    display: none;
  }
}


// Dropright

.navbar-nav .dropright > .dropdown-toggle {
  display: flex;
}

.navbar-nav .dropright > .dropdown-toggle::after {
  margin-left: auto;
  font-family: 'Feather';
  color: $gray-500;
  content: "\e930";
}


// Dropdown menu

.navbar-nav .dropdown-positioner {
  position: absolute;
  z-index: $zindex-dropdown;
}

.navbar-nav .dropdown-positioner > .dropdown-menu {
  position: static;
}

