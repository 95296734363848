$navbar-item-border-color: rgba($black, 0.065);
$navbar-item-border-color-dark-bg: #343040;

@mixin navbar-item-border-top {
  // copy from theme/_navbar.scss
  border-top: $border-width solid $navbar-item-border-color !important;
}

@mixin navbar-item-border-bottom {
  border-bottom: $border-width solid $navbar-item-border-color !important;
}

@mixin navbar-item-border-top-dark-bg {
  border-top: $border-width solid $navbar-item-border-color-dark-bg !important;
}
