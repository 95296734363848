//
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

// Colors

$black: #323232; // TODO: try using scss.erb loader and configuring this value in one place (Rails config)
$black-original: #000;
$pearl-bush: #eeeae2;
$porcelain: #eaedee;
$raw-sienna: #d18f42;
$quick-silver: #a6a6a6;
$white: #ffffff;

$primary: #6998ae;
$secondary: #b56941;
$body-color: #5c5c5c;

$footer-bg: #414042;
$trending-sections-bg: $pearl-bush;

$subheadings-primary: $primary;
$subheadings-secondary: $raw-sienna;

$decorations-primary: $primary;
$decorations-secondary: #ffc267;

$underlines-secondary: change-color($decorations-secondary, $alpha: 0.6);

$navbar-light-color: $black;

$profile-bg: #f3f4f5;
$profile-button-bg: #f9f9f9;
$profile-account-button-bg: #e4eef3;
$profile-label-color: #808285;
$profile-modal-xs-padding: 0 12.5% 12.5%;
$profile-modal-padding: 0.625rem 5.5rem 5.5rem;
$profile-modal-xs-button-padding: 0.625rem 25%;
$profile-modal-button-padding: 0.625rem 7.5rem;

// Grid

$xxl-width: 1440px;

// Typography

$font-size-base: 1.125rem; // 18px

$headings-font-weight: normal;
$headings-color: $black;
$headings-margin-bottom: 27px;

$h1-font-size: 4rem; // 64px
$h1-font-size-sm: 2rem; // 32px
$h1-line-height: 1.08; // for both h1 and h2 headings

$h2-font-size: 3rem; // 48px
$h2-font-size-sm: 2rem; // 32px

$h3-font-size: 2.25rem; // 36px
$h3-font-size-sm: 2rem; // 32px
$h3-line-height: 132%;

$h4-font-size: 1.75rem; // 28px
$h4-font-size-sm: 1.625rem !default; // 26px; new variable, not from Goodkit

$h5-font-size: 1.375rem; // 22px
$h5-font-size-sm: 1.25rem !default; // 20px; new variable, not from Goodkit

$h6-font-size: 1.25rem; // 20px
$h6-font-size-sm: 1.125rem !default; // 18px; new variable, not from Goodkit

// Navbar

$navbar-nav-link-padding-x: 1.55rem;
$navbar-padding-y: 1.68rem;
$navbar-padding-x: 1.5rem;

// Buttons

$btn-letter-spacing: -0.01em;

// Flickity
$flickity-dot-size: 1rem;
$flickity-dot-bg: #fff; // cannot use $white because it's defined in _variables.scss
$flickity-dot-active-bg: #9c9c9c;

// Form
$border-invalid-input: 1px #D35163 solid !important;
$border-not-placeholder-shown: 1px #6998AE solid;
$border-placeholder-shown: 1px #D1D3D4 solid;
$input-placeholder-color: $quick-silver;
$input-disabled-bg-color: #f9f9f9;
$input-font-size: 1rem;


