.btn {
  letter-spacing: $btn-letter-spacing;
}

.btn-primary-default {
  @extend .btn;
  @extend .btn-primary;
  @extend .lift;
}

.btn-white-default {
  @extend .btn;
  @extend .btn-white;
  @extend .lift;
}
