.footer {
  @extend .py-8;
  @extend .py-md-10;
  @extend .border-top;
  @extend .border-gray-900;

  background-color: $footer-bg;

  .footer-logo {
    margin-right: $grid-gutter-width;
    margin-bottom: 3rem;

    svg {
      fill: $white;
    }
  }

  .footer-submenu-col {
    @extend .pr-0;

    margin-bottom: 2.375rem;
  }

  .footer-container {
    @include media-breakpoint-down(sm) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .footer-donate-options {
    margin-bottom: 90px;
    a {
      color: rgba(255, 255, 255, 0.6) !important;
      margin-bottom: 0.5rem !important;
      display: block;
    }
  }

  .footer-join-us {
    ul.nav {
      flex-direction: row;
      gap: 16px;
    }
  }
}
