.collapsible-text {
  .collapse:not(.show) {
    @extend .d-block;

    overflow: hidden;
  }

  .collapsible-text-toggle {
    @include transparent-background;
    @extend .border-0;
    @extend .pl-0;
    @extend .mt-n1;

    display: block;
    color: $white;
    font-weight: $font-weight-bold;

    &[aria-expanded='false'] {
      .show-less-toggle {
        @extend .d-none;
      }
    }

    &[aria-expanded='true'] {
      .read-full-toggle {
        @extend .d-none;
      }
    }
  }
}
