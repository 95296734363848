//
// navbar.scss
//

// Mobile

@include media-breakpoint-down(lg) {
  // Fixed

  .navbar.fixed-top {
    max-height: 100vh;
  }

  // Nav

  .navbar-nav:first-child {
    margin-top: $navbar-padding-y;
  }

  .navbar-nav > .nav-item {
    border-top: $border-width solid rgba($black, 0.065);
  }

  .navbar-nav > .nav-item > .nav-link {
    display: flex;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  // Dropdown

  .navbar-nav > .dropdown > .dropdown-toggle::after {
    margin-left: auto;
    font-family: 'Feather';
    color: $gray-500;
    content: '\e92e';
    transform-origin: center center;
  }

  .navbar-nav > .dropdown.show > .dropdown-toggle::after {
    transform: rotate(180deg);
  }

  .navbar-nav > .dropdown .dropdown-menu,
  .navbar-nav > .dropdown .dropdown-menu-col {
    min-width: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .navbar-nav > .dropdown .dropdown-menu {
    padding: 0.75rem 0 1.5rem;
  }

  .navbar-nav > .dropdown .dropdown-menu-col {
    background-color: transparent !important;
  }

  .navbar-nav > .dropdown .dropdown-menu-col .text-white {
    color: $body-color !important;
  }

  .navbar-nav > .dropdown .dropdown-menu-body {
    padding: 0;
  }

  // Dropright

  .navbar-nav .dropright > .dropdown-toggle::after {
    content: '\e92e';
    transform-origin: center center;
  }

  .navbar-nav .dropright.show > .dropdown-toggle::after {
    transform: rotate(180deg);
  }

  .navbar-nav .dropright .dropdown-menu {
    padding: 0.75rem 0 0 0.75rem;
  }

  // Dropdown menu

  .navbar-nav .dropdown-positioner {
    position: relative !important;
    transform: none !important;
  }

  // Text

  .navbar-nav .text-gray-500 {
    color: $text-muted !important;
  }
}

// Desktop

@include media-breakpoint-up(xl) {
  // Dropright

  .navbar-nav .dropright {
    width: auto;
    margin-left: -$dropdown-padding-x;
    margin-right: -$dropdown-padding-x;
    padding-left: $dropdown-padding-x;
    padding-right: $dropdown-padding-x;
  }

  // Toggle on hover

  .navbar-nav .dropdown-menu {
    display: none;
    opacity: 0;
    transform: translateY(10px);
    transition: $transition-base;
    transition-property: opacity, transform, -webkit-transform;
  }

  .navbar-nav .dropdown-menu.showing {
    display: block;
  }

  .navbar-nav .dropdown-menu.show {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
}

.profile-logo-header .notification-count,
#profile-dropdown .notification-count {
  position: absolute;
  top: -3px;
  left: 12px;
  background-color: #D35163;
  border-radius: 50%;
  font-size: 12px;
  color: #FFFFFF;
  width: 20px;
  height: 20px;
  text-align: center;
  &:empty {
    visibility: hidden;
  }
}
#profile-dropdown .notification-count {
  left: 20px;
}
.nav-item-icon {
  position: relative;
  .username {
    background: #8F8A9B;
    border-radius: 50%;
    color: white;
    width: 32px;
    text-align: center;
    font-weight: 400;
  }
}
