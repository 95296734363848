.topic {
  @extend .d-inline-block;
  @extend .align-top;
  @extend .mw-100;
  padding: 0.25rem 0.625rem 0.25rem;

  font-size: $font-size-xxs;
  line-height: 1rem;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;

  background-color: #3B3946;
  color: #D1D3D4;

  border: 1px solid #444050;
  box-sizing: border-box;
  border-radius: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;

  .topic-link {
    color: inherit;
    white-space: nowrap;
  }
}
